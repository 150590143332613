import type { ReactNode } from 'react';
import type { Merge } from 'type-fest';

import type { VariantProps } from '../../types.js';
import { Button } from '../button/index.js';
import { CancelIcon } from '../icons/cancel-icon.js';
import { Text } from '../text/index.js';
import * as Primitive from './drawer.primitive.js';

export type DrawerProps = Merge<
  VariantProps<typeof Primitive.Body>,
  {
    children: ReactNode;
    size: 'small' | 'large';
    header: string;
    trigger?: ReactNode;
    open?: boolean;
  }
>;

/**
 * @deprecated
 */
export function Drawer({
  children,
  size,
  placement,
  header,
  trigger,
  open,
  ...props
}: DrawerProps) {
  return (
    <Primitive.Root defaultOpen={open} {...props}>
      {trigger}
      <Primitive.Portal>
        <Primitive.Overlay />
        <Primitive.Body placement={placement} size={size}>
          {/* TODO: SwipeBar is being left out of this implementation until we have a "swipe away" gesture to mimic iOS functionality, just uncomment this when ready to add back in, and it should still be styled correctly */}
          {/* <Primitive.SwipeBarContainer>
          <Primitive.SwipeBar />
        </Primitive.SwipeBarContainer> */}
          <Primitive.Header placement={placement}>
            <Text
              as="h3"
              data-test="drawer-header"
              kind={{
                '@initial': 'h5',
                '@large': 'h3',
              }}
              lines={1}
            >
              {header}
            </Text>
            <Primitive.Trigger asChild data-test="close-button">
              <Button
                color={{
                  dark: 'white',
                  light: 'gray',
                }}
                kind="tertiary"
                size="icon"
                tabIndex={0}
              >
                <CancelIcon size={24} />
              </Button>
            </Primitive.Trigger>
          </Primitive.Header>
          <Primitive.Content data-test="drawer-content" placement={placement}>
            {children}
          </Primitive.Content>
        </Primitive.Body>
      </Primitive.Portal>
    </Primitive.Root>
  );
}
