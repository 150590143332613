import { type ReactNode, forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Box } from '../box/index.js';
import { Button } from '../button/index.js';
import { OverflowHorizontalIcon } from '../icons/overflow-horizontal-icon.js';
import { Menu } from '../menu/index.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

export const PlayerMenu = forwardRef<
  HTMLButtonElement,
  {
    children: ReactNode;
    disabled?: boolean;
    onChange?: (value: boolean) => void;
    open?: boolean;
  }
>(function PlayerMenu({ children, disabled, onChange, open, ...props }, ref) {
  return (
    <Box
      css={{
        display: 'none',
        padding: '$0 $8',

        [`@container ${media.large}`]: {
          display: 'block',
        },

        '[data-player-type="fullscreen"] &': {
          display: 'block',
          padding: '$0 $6',

          [`@container ${media.large}`]: {
            display: 'none',
          },
        },
      }}
      gridArea="menu"
      {...props}
    >
      <Menu
        onChange={onChange}
        open={open}
        trigger={
          <Box pointerEvents="none">
            <Tooltip
              side="top"
              trigger={
                <Button
                  color={{ dark: 'white', light: 'gray' }}
                  continuePropagation
                  disabled={disabled}
                  kind="tertiary"
                  ref={ref}
                  size="icon"
                >
                  <OverflowHorizontalIcon size={32} />
                </Button>
              }
            >
              <Text
                color={{
                  dark: '$brand-black',
                  light: '$brand-white',
                }}
                kind="caption-3"
              >
                More Options
              </Text>
            </Tooltip>
          </Box>
        }
        {...props}
        zIndex="$10"
      >
        {children}
      </Menu>
    </Box>
  );
});

PlayerMenu.displayName = 'Player.Menu';

export const PlayerMenuItem = Menu.Item;

PlayerMenuItem.displayName = 'Player.MenuItem';
