import type { ReactNode } from 'react';
import { Children, forwardRef, isValidElement, useState } from 'react';
import { isEmpty, isNonNullish, isString } from 'remeda';

import { Theme } from '../../stitches.config.js';
import type { Component } from '../../types.js';
import { Box } from '../box/index.js';
import { Button } from '../button/index.js';
import { Flex } from '../flex/index.js';
import { Grid } from '../grid/index.js';
import { CancelIcon } from '../icons/cancel-icon.js';
import { CheckFilledIcon } from '../icons/check-filled-icon.js';
import { ErrorFilledIcon } from '../icons/error-filled-icon.js';
import { InfoFilledIcon } from '../icons/info-filled-icon.js';
import { WarningFilledIcon } from '../icons/warning-filled.js';
import { Text } from '../text/index.js';
import { ThemeProvider, useTheme } from '../theme/index.js';

const backgroundColorMap = {
  error: '$red-100',
  info: '$blue-100',
  neutral: { dark: '$gray-600', light: '$brand-white' },
  success: '$green-100',
  warning: '$yellow-300',
} as const;

const IconMap = ({ kind }: { kind: NotificationKind }) => {
  switch (kind) {
    case 'error': {
      return <ErrorFilledIcon fill="red-650" />;
    }
    case 'info': {
      return <InfoFilledIcon fill="blue-600" />;
    }
    case 'neutral': {
      return null;
    }
    case 'success': {
      return <CheckFilledIcon fill="green-600" />;
    }
    case 'warning': {
      return <WarningFilledIcon fill="orange-550" />;
    }
    // No default
    default: {
      return null;
    }
  }
};

export type NotificationKind =
  | 'neutral'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

/**
 * @deprecated
 */
export type NotificationProps = {
  children?: ReactNode;
  footer?: ReactNode;
  title?: string;
  kind?: NotificationKind;
  onDismiss?: () => void;
  icon?: ReactNode;
  showClose?: boolean;
};

/**
 * @deprecated
 */
export const Notification = forwardRef<HTMLDivElement, NotificationProps>(
  function Notification(
    {
      children,
      footer,
      title,
      onDismiss,
      icon,
      kind = 'neutral',
      showClose = false,
      ...props
    },
    ref,
  ) {
    const defaultTheme = useTheme();
    const reverseTheme = defaultTheme === Theme.Dark ? Theme.Light : Theme.Dark;
    const theme = kind === 'neutral' ? reverseTheme : Theme.Light;
    const [showNotification, setShowNotification] = useState(true);

    const isDismissable = showClose || onDismiss;

    if (!showNotification) {
      return null;
    }

    return (
      <ThemeProvider controlled value={theme}>
        <Box
          backgroundColor={backgroundColorMap[kind]}
          borderRadius="$6"
          data-theme={theme}
          display="grid"
          minHeight="5.6rem"
          padding="$16"
          ref={ref}
          width="100%"
          {...props}
        >
          <Grid
            alignItems="center"
            columnGap="$8"
            templateColumns={`${kind === 'neutral' ? '' : 'auto '}1fr`}
          >
            <Box alignSelf="start">{icon ?? <IconMap kind={kind} />}</Box>
            <Flex
              direction="column"
              gap={isNonNullish(children) || isNonNullish(footer) ? '$2' : '$0'}
            >
              <Grid
                alignItems={isEmpty(title) ? 'start' : 'center'}
                columnGap="$8"
                templateColumns={`1fr${isDismissable ? ' auto' : ''}`}
              >
                <Flex direction="column" gap="$2">
                  {title ?
                    <Text
                      as="h5"
                      color={{ light: '$gray-600', dark: '$gray-200' }}
                      kind={{ '@initial': 'subtitle-2', '@medium': 'h5' }}
                    >
                      {title}
                    </Text>
                  : null}
                  {isNonNullish(children) ?
                    Children.map(children, child =>
                      isString(child) ?
                        <Text
                          as="p"
                          color={{ light: '$gray-600', dark: '$gray-200' }}
                          kind={{ '@initial': 'body-4', '@medium': 'body-3' }}
                        >
                          {child}
                        </Text>
                      : isValidElement(child) ? child
                      : null,
                    )
                  : null}
                </Flex>
                {isDismissable ?
                  <Box alignSelf="start">
                    <Button
                      color={{ dark: 'white', light: 'gray' }}
                      data-test="close-button"
                      kind="tertiary"
                      onClick={() => {
                        // Default onClick behavior dismisses the notification
                        setShowNotification(false);
                        // Any additional functionality needed when dismissing can be passed in the onDismiss prop, and called here
                        onDismiss?.();
                      }}
                      size="icon"
                    >
                      <CancelIcon size={24} />
                    </Button>
                  </Box>
                : null}
              </Grid>
              {!!footer && (
                <Flex columnGap="$8" justifyContent="end" paddingTop="$12">
                  {footer}
                </Flex>
              )}
            </Flex>
          </Grid>
        </Box>
      </ThemeProvider>
    );
  },
) as Component<'div', NotificationProps>;
