import '@iheartradio/web.accomplice/globals';

import { lightDark } from '@iheartradio/web.accomplice';
import { vars } from '@iheartradio/web.accomplice';
import { RouterProvider, ThemeProvider } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { GlobalToastRegion } from '@iheartradio/web.accomplice/toast';
import { core, ThemeContext } from '@iheartradio/web.companion';
import companionNormalizeCSSUrl from '@iheartradio/web.companion/normalize.css?url';
import companionResetCSSUrl from '@iheartradio/web.companion/reset.css?url';
import type { BaseConfig } from '@iheartradio/web.config';
import {
  ClientHintCheck,
  getHints,
} from '@iheartradio/web.remix-shared/client-hints.js';
import type { DocumentSharedProps } from '@iheartradio/web.remix-shared/document.js';
import {
  METADATA_APP_NAME,
  METADATA_DEFAULT_IMAGE,
  METADATA_DOMAIN,
  METADATA_GLOBAL_DESCRIPTION,
  METADATA_GLOBAL_KEYWORDS,
  METADATA_GLOBAL_TITLE,
  METADATA_OPENGRAPH_TYPES,
  METADATA_TWITTER_CARDS,
  METADATA_TWITTER_HANDLE,
} from '@iheartradio/web.remix-shared/metadata/constants.js';
import { setBasicMetadata } from '@iheartradio/web.remix-shared/metadata/utilities.js';
import {
  LoginRegistrationCookieJar,
  ThemeCookie,
} from '@iheartradio/web.remix-shared/node/cookies.server.js';
import type { RequestInfo } from '@iheartradio/web.remix-shared/react/request-info.js';
import { useAbsoluteHref } from '@iheartradio/web.remix-shared/react/router.js';
import { useTheme } from '@iheartradio/web.remix-shared/react/theme.js';
import { useAppOpenClose } from '@iheartradio/web.remix-shared/react/use-app-open-close.js';
import { useTrackVisibilityChange } from '@iheartradio/web.remix-shared/react/use-track-visibility-change.js';
import { getGeolocationForRequest } from '@iheartradio/web.remix-shared/server/geolocation.js';
import { NoCacheHeaders } from '@iheartradio/web.remix-shared/server/headers.js';
import { getDomainUrl } from '@iheartradio/web.remix-shared/server/http.js';
import { getThemeFromRequest } from '@iheartradio/web.remix-shared/server/theme.js';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import {
  type LinksFunction,
  type LoaderFunctionArgs,
  type SerializeFrom,
  type ServerRuntimeMetaFunction,
  json,
} from '@remix-run/server-runtime';
import { useContext, useEffect, useRef } from 'react';
import { isEmpty, isNonNullish } from 'remeda';
import { ClientOnly } from 'remix-utils/client-only';
import { resolveAcceptLanguage as _resolveAcceptLanguage } from 'resolve-accept-language';

import { DevelopmentBanner } from '~app/components/development-banner';
import { AppErrorBoundary } from '~app/components/error/app-error-boundary';
import { getConfigFromRequest } from '~app/config.server';
import { ConfigProvider } from '~app/contexts/config';
import { UserContext } from '~app/contexts/user';
import { getIsDevelopment } from '~app/utilities/utilities.server';

import { Analytics, useAnalytics } from './analytics';
import ClientStyleContext from './contexts/client-style';
import { getServerContext } from './get-server-context.server';
import { useGetPageName } from './hooks/use-page-name';
import { useRootLoaderData } from './hooks/use-root-loader-data';

function resolveAcceptLanguage(acceptLanguageHeader: string | null) {
  if (!acceptLanguageHeader) {
    return 'en-US' as const;
  }

  return _resolveAcceptLanguage(
    acceptLanguageHeader,
    ['en-US'] as const,
    'en-US',
  );
}

export const links: LinksFunction = () => [
  // Font loading
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap',
  },
  { rel: 'stylesheet', href: companionNormalizeCSSUrl },
  { rel: 'stylesheet', href: companionResetCSSUrl },

  { rel: 'stylesheet', href: '/recurly.css' },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const config = getConfigFromRequest(request);
  const { isMobile, user, authEvent } = await getServerContext(request);
  const { CONFIG_ENV, npm_package_version, SHORT_COMMIT = '' } = process.env;
  const isDevelopment = getIsDevelopment();
  const headers = new Headers(NoCacheHeaders);

  if (isNonNullish(authEvent)) {
    headers.append(
      'Set-Cookie',
      await LoginRegistrationCookieJar.serialize(
        {},
        {
          expires: new Date(0),
          httpOnly: true,
        },
      ),
    );
  }

  const hints = getHints(request);

  return json(
    {
      appVersion: npm_package_version ?? '',
      authEvent,
      config,
      geolocation: getGeolocationForRequest(request),
      isDevelopment,
      user,
      CONFIG_ENV,
      SHORT_COMMIT,
      // This block is what the new `useTheme` hook uses to determine the correct theme from browser
      // hints or the explicit theme that the user has chosen
      requestInfo: {
        hints: {
          ...hints,
          theme: (await getThemeFromRequest(request)) ?? hints.theme,
        },
        isMobile,
        locale: resolveAcceptLanguage(request.headers.get('Accept-Language')),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: await ThemeCookie.parse(request.headers.get('Cookie')),
        },
      } satisfies RequestInfo,
    },
    { headers },
  );
};

export type RootLoader = typeof loader;
export type RootLoaderData = SerializeFrom<RootLoader>;

export const meta: ServerRuntimeMetaFunction<typeof loader> = ({ data }) => {
  const { config } = data ?? {};

  return [
    ...setBasicMetadata({
      title: METADATA_GLOBAL_TITLE,
      description: METADATA_GLOBAL_DESCRIPTION,
      keywords: METADATA_GLOBAL_KEYWORDS,
      image: METADATA_DEFAULT_IMAGE,
      type: METADATA_OPENGRAPH_TYPES.Website,
      card: METADATA_TWITTER_CARDS.Summary,
      url: 'https://account.iheart.com', // TODO: app url generation should use a common function
    }),
    { content: METADATA_APP_NAME, property: 'og:site_name' },
    { content: METADATA_DOMAIN, name: 'twitter:domain' },
    { content: METADATA_TWITTER_HANDLE, name: 'twitter:creator' },
    { content: METADATA_TWITTER_HANDLE, name: 'twitter:site' },
    { content: METADATA_APP_NAME, name: 'twitter:app:name:iphone' },
    { content: METADATA_APP_NAME, name: 'twitter:app:name:ipad' },
    { content: METADATA_APP_NAME, name: 'twitter:app:name:googleplay' },
    { content: METADATA_APP_NAME, name: 'al:android:app_name' },
    { content: METADATA_APP_NAME, name: 'al:ios:app_name' },
    { content: core.colors['brand-red'], name: 'theme-color' },
    ...(config ?
      [
        config?.sdks?.facebook?.appId ?
          { content: config.sdks.facebook.appId, property: 'fb:app_id' }
        : null,
        config?.sdks?.facebook?.pages ?
          { content: config.sdks.facebook.pages, property: 'fb:pages' }
        : null,
        { content: config.app.appleId, name: 'twitter:app:id:iphone' },
        { content: config.app.appleId, name: 'twitter:app:id:ipad' },
        { content: config.app.appleId, name: 'al:ios:app_store_id' },
        {
          content: config.app.googlePlayId,
          name: 'twitter:app:id:googleplay',
        },
        { content: config.app.googlePlayId, name: 'al:android:package' },
      ].filter(isNonNullish)
    : []),
  ];
};

export default function App() {
  const { authEvent, user, config } = useLoaderData<typeof loader>();

  const authEventRef = useRef<string>();

  const analytics = useAnalytics();

  useTrackVisibilityChange(analytics);

  if (
    authEvent &&
    !isEmpty(authEvent) &&
    authEventRef.current !== authEvent.type
  ) {
    authEventRef.current = authEvent.type;
    analytics.track(authEvent);
    authEventRef.current = '';
  }

  return (
    <Document>
      <UserContext.Provider value={user}>
        <ConfigProvider value={config}>
          <Analytics user={user} />
          <ClientOnly>{() => <GlobalToastRegion />}</ClientOnly>
          <Outlet />
        </ConfigProvider>
      </UserContext.Provider>
    </Document>
  );
}

export interface DocumentProps extends DocumentSharedProps {
  isDevelopment?: boolean;
  config?: BaseConfig;
  CONFIG_ENV?: string;
  SHORT_COMMIT?: string;
}

const Document = (props: DocumentProps) => {
  const { children } = props;
  const analytics = useAnalytics();

  const {
    config,
    SHORT_COMMIT,
    isDevelopment = false,
    appVersion,
  } = useRootLoaderData();

  const theme = useTheme();

  const pageName = useGetPageName();

  useAppOpenClose(analytics, 'account', appVersion, pageName);

  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  useEffect(() => {
    // Reset cache to re-apply global styles
    clientStyleData.reset();
  }, [clientStyleData]);

  const navigate = useNavigate();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="on" httpEquiv="x-dns-prefetch-control" />
        <meta content="yes" name="mobile-web-app-capable" />
        <Meta />
        <ClientHintCheck />
        <Links />
        <style
          dangerouslySetInnerHTML={{ __html: clientStyleData.sheet }}
          id="stitches"
          suppressHydrationWarning
        />
        <script async src="https://js.recurly.com/v4/recurly.js"></script>
        <ClientOnly>
          {() =>
            config?.sdks.googleRecaptcha.v3SiteKey ?
              <script
                async
                src={`https://www.google.com/recaptcha/enterprise.js?render=${config?.sdks.googleRecaptcha.v3SiteKey}`}
              ></script>
            : null
          }
        </ClientOnly>
      </head>
      <ThemeProvider value={theme}>
        <ThemeContext.Provider value={theme}>
          <Box
            asChild
            backgroundColor={lightDark(
              vars.color.gray100,
              vars.color.brandBlack,
            )}
            data-theme={theme}
            data-version={SHORT_COMMIT}
          >
            <body>
              <RouterProvider navigate={navigate} useHref={useAbsoluteHref}>
                <div id="fb-root" />
                {isDevelopment ?
                  <DevelopmentBanner />
                : null}
                {children}
                <ScrollRestoration />
                <Scripts />
              </RouterProvider>
            </body>
          </Box>
        </ThemeContext.Provider>
      </ThemeProvider>
    </html>
  );
};

export const ErrorBoundary = () => <AppErrorBoundary document={Document} />;
