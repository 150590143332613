import { keyframes, styled } from '../../stitches.config.js';
import { Flex } from '../flex/index.js';
import { kinds } from '../text/text.js';

const brandWhite = '$brand-white';
const brandBlack = '$brand-black';

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

/**
 * @deprecated
 */

export const Navigation = styled('nav', {
  bottom: '0',
  borderTop: '1px solid',
  boxShadow: '$elevation-1',
  display: 'flex',
  height: '6rem',
  justifyContent: 'center',
  left: '0',
  position: 'fixed',
  width: '$12',

  button: {
    animation: `${fadeIn} 100ms`,
    animationIterationCount: 1,
    animationFillMode: 'forwards',
  },

  '@large': {
    border: 'none',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'flex-start',
    left: '0',
    overflowX: 'hidden',
    top: '0',
    width: '31.6rem',
    zIndex: '$5',
  },

  dark: {
    backgroundColor: '$gray-600',
    borderColor: '$gray-500',
  },

  light: {
    backgroundColor: brandWhite,
    borderColor: '$gray-200',
  },
});

Navigation.displayName = 'Navigation';

export const Header = styled('div', {
  alignItems: 'center',
  borderBottom: '1px solid',
  boxShadow: '$elevation-1',
  display: 'flex',
  height: '4.8rem',
  justifyContent: 'space-between',
  left: '0',
  position: 'fixed',
  top: '0',
  width: '$12',
  alignContent: 'center',
  padding: '$0 $16',
  flexWrap: 'wrap',

  '@large': {
    display: 'none',
  },

  svg: {
    display: 'inline-block',

    '@large': { display: 'none' },
  },

  a: {
    display: 'inline-block',

    '@large': { display: 'none' },
  },

  dark: {
    backgroundColor: '$gray-600',
    borderColor: '$gray-500',
  },

  light: {
    backgroundColor: brandWhite,
    borderColor: '$gray-200',
  },
});

Header.displayName = 'Navigation.Header';

export const Logo = styled('div', {
  display: 'none',
  padding: '$32 0 $40 $16',

  '@large': {
    display: 'flex',
    width: '100%',
    alignSelf: 'center',
  },

  svg: {
    display: 'none',

    '@large': { display: 'block' },
  },
});

Logo.displayName = 'Navigation.Logo';

export const List = styled(Flex, {
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '$8',
  justifyContent: 'center',
  width: '$11',

  '@large': {
    flexDirection: 'column',
  },
});

List.displayName = 'Navigation.List';

export const Item = styled('a', {
  alignItems: 'center',
  borderRadius: '$6',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '$12',
  gap: '.5rem',
  justifyContent: 'center',
  outline: 'none',
  padding: '$4',
  textDecoration: 'none',
  transition: 'all ease 300ms',
  width: '$10',

  '@initial': kinds['caption-4'],
  '@medium': {
    fontSize: '$14',
  },

  '@large': {
    ...kinds['caption-2'],
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '$6',
    justifyContent: 'flex-start',
    padding: '$8 $16',
    width: '$12',
  },

  '&, & *': {
    dark: {
      color: '$gray-250',
    },

    light: {
      color: '$gray-450',
    },
  },

  '&[aria-disabled="true"]': {
    '&, & *': {
      cursor: 'default',
      pointerEvents: 'none',
      dark: {
        color: '$gray-400 !important',
        background: 'transparent !important',

        '&, & *': {
          color: '$gray-400',
        },
      },

      light: {
        color: '$gray-300 !important',
        backgroundColor: 'transparent !important',

        '&, & *': {
          color: '$gray-300',
        },
      },
    },
    hover: {
      dark: {
        backgroundColor: 'transparent',
        color: '$gray-400',
      },

      light: {
        backgroundColor: 'transparent',
        color: '$gray-300',
      },
      svg: {
        dark: {
          fill: '$gray-400',
        },

        light: {
          fill: '$gray-300',
        },
      },
    },

    dark: {
      color: '$gray-400',
    },

    light: {
      color: '$gray-300',
    },

    svg: {
      dark: {
        fill: '$gray-400 !important',
      },

      light: {
        fill: '$gray-300 !important',
      },
    },
  },

  svg: {
    dark: {
      fill: '$gray-250',
    },

    light: {
      fill: '$gray-450',
    },
  },

  focusVisible: {
    borderRadius: '$6',
    outlineOffset: '$0',
    transition: 'none',

    dark: {
      outline: `1px solid ${brandWhite}`,
    },

    light: {
      outline: '1px solid $gray-600',
    },
  },

  hover: {
    dark: {
      background: '$gray-400',

      '&, & *': {
        color: brandWhite,
      },
    },

    light: {
      background: '$gray-300',

      '&, & *': {
        color: brandBlack,
      },
    },

    svg: {
      dark: {
        fill: brandWhite,
      },

      light: {
        fill: brandBlack,
      },
    },
  },

  pressed: {
    dark: {
      background: '$gray-500',

      '&, & *': {
        color: brandWhite,
      },
    },

    light: {
      background: '$gray-200',

      '&, & *': {
        color: brandBlack,
      },
    },

    svg: {
      dark: {
        fill: brandWhite,
      },

      light: {
        fill: brandBlack,
      },
    },
  },

  variants: {
    active: {
      true: {
        '&, & *': {
          dark: {
            color: brandWhite,
          },

          light: {
            color: brandBlack,
          },
        },

        hover: {
          dark: {
            backgroundColor: brandBlack,
          },

          light: {
            backgroundColor: '$gray-100',
          },
        },

        light: {
          backgroundColor: '$gray-100',
        },

        dark: {
          backgroundColor: brandBlack,
        },

        svg: {
          dark: {
            fill: brandWhite,
          },

          light: {
            fill: brandBlack,
          },
        },
      },
    },
  },
});

Item.displayName = 'Navigation.Item';
