import { type FlexProps, Flex } from '../flex/index.js';

/**
 * @deprecated
 */
export const DialogButtonContainer = ({ children, ...props }: FlexProps) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    gap="$16"
    justifyContent="center"
    {...props}
  >
    {children}
  </Flex>
);
