import { Badge } from '@iheartradio/web.accomplice/badge';
import { Box } from '@iheartradio/web.accomplice/box';
import { addToast } from '@iheartradio/web.accomplice/toast';

export const DevelopmentBanner = () => {
  return (
    <Box
      cursor="pointer"
      onClick={() => {
        addToast({
          kind: 'info',
          title: 'Development Mode?',
          text: 'AMP config is set to use only clientEndpoint and any network calls that require the VPN will exit early. Use the following command to run in production mode: CONFIG_ENV=production pnpm -F @iheartradio/web.account dev',
        });
      }}
      position="fixed"
      right="0"
      role="marquee"
      top="0"
      zIndex={2}
    >
      <Badge css={{ backgroundColor: '$brandRed' }}>Dev Mode</Badge>
    </Box>
  );
};
