import { type IconProps, Icon } from './icon.js';

export function FacebookColor(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Facebook Color" {...props}>
      <svg viewBox="0 0 18 18">
        <path
          d="M18 9a9 9 0 1 0-10.4 8.9v-6.3H5.3V9h2.3V7.02c0-2.26 1.34-3.5 3.4-3.5.98 0 2 .17 2 .17v2.22h-1.13c-1.12 0-1.46.7-1.46 1.4V9h2.5l-.4 2.6h-2.1v6.3A9 9 0 0 0 18 9Z"
          fill="#0866ffff"
        />
        <path
          d="m12.5 11.6.4-2.6h-2.5V7.31c0-.7.35-1.4 1.47-1.4h1.14V3.69s-1.03-.17-2.02-.17c-2.05 0-3.4 1.24-3.4 3.5V9H5.31v2.6h2.28v6.3a9.05 9.05 0 0 0 2.82 0v-6.3h2.1Z"
          fill="#fff"
        />
      </svg>
    </Icon>
  );
}
