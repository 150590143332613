import { type IconProps, Icon } from './icon.js';

export function GoogleColor(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Google Color" {...props}>
      <svg viewBox="0 0 18 18">
        <path
          clipRule="evenodd"
          d="M17.82 9.2c0-.63-.06-1.25-.16-1.84H9.18v3.49h4.84a4.14 4.14 0 0 1-1.8 2.71v2.26h2.92a8.78 8.78 0 0 0 2.68-6.62Z"
          fill="#4285F4"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9.18 18a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.4 5.4 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 9.18 18Z"
          fill="#34A853"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M4.14 10.71a5.41 5.41 0 0 1 0-3.42V4.96h-3a9 9 0 0 0 0 8.08l3-2.33Z"
          fill="#FBBC05"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9.18 3.58c1.32 0 2.5.45 3.44 1.35l2.58-2.59A9 9 0 0 0 1.14 4.95l3 2.34a5.36 5.36 0 0 1 5.04-3.71Z"
          fill="#EA4335"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  );
}
