import { invariant } from '@epic-web/invariant';
import { Theme } from '@iheartradio/web.companion';
import { useRouteLoaderData } from '@remix-run/react';

export interface RequestInfo {
  hints: {
    theme: Theme;
    reducedMotion: 'reduce' | 'no-preference';
    timeZone: string;
  };
  userPrefs: {
    theme: Theme | null;
  };
  locale: string;
  isMobile: boolean;

  [key: string]: unknown;
}

/**
 * @returns the request info from the root loader
 */
export function useRequestInfo<T extends RequestInfo>(
  rootError = false,
): T | RequestInfo {
  const data = useRouteLoaderData('root') as {
    requestInfo?: T | undefined;
  };

  if (rootError) {
    // root error pages are always light theme
    return {
      hints: {
        reducedMotion: 'no-preference',
        theme: Theme.Light,
        timeZone: 'UTC',
      },
      userPrefs: { theme: Theme.Light },
      isMobile: false,
      locale: 'en-US' as const,
    } satisfies RequestInfo;
  }

  invariant(data?.requestInfo, 'No requestInfo found in root loader');

  return data.requestInfo;
}
