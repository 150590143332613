import { Notification } from '@iheartradio/web.accomplice/notification';
import { type Dispatch, type SetStateAction, useState } from 'react';

export const ErrorNotification = ({
  provider,
  setShowNotification,
}: {
  provider: 'Facebook' | 'Google' | null;
  setShowNotification: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Notification
      data-test="social-login-error"
      kind="error"
      onDismiss={() => setShowNotification(false)}
      title={`There was an error with ${provider}. Please try again.`}
    />
  );
};

export const useSocialError = () => {
  const [provider, setProvider] = useState<'Facebook' | 'Google' | null>(null);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const SocialLoginErrorNotification = () => {
    return showNotification ?
        <ErrorNotification
          provider={provider}
          setShowNotification={setShowNotification}
        />
      : null;
  };

  return {
    SocialLoginErrorNotification,
    setProvider,
    setShowNotification,
  };
};
