import { type IconProps, Icon } from './icon.js';

export function VisibilityOff(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Visibility Off" {...props}>
      <svg viewBox="0 0 24 25">
        <path d="M12 7.94a5 5 0 0 1 4.64 6.83l2.92 2.92a11.82 11.82 0 0 0 3.43-4.75 11.83 11.83 0 0 0-14.98-6.8l2.16 2.16A4.85 4.85 0 0 1 12 7.94ZM2 5.2l2.28 2.28.46.46a11.8 11.8 0 0 0-3.74 5 11.83 11.83 0 0 0 15.38 6.66l.42.42 2.93 2.92L21 21.67 3.27 3.94 2 5.2Zm5.53 5.53 1.55 1.55a3 3 0 0 0 3.57 3.57l1.55 1.55a4.96 4.96 0 0 1-6.67-6.67Zm4.31-.78 3.15 3.15.02-.16a3 3 0 0 0-3-3h-.17Z" />
      </svg>
    </Icon>
  );
}
