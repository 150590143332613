import { type ComponentType, type ForwardedRef, forwardRef } from 'react';
import type { SetOptional } from 'type-fest';

import { media } from '../../core/index.js';
import { type BoxProps, Box } from '../box/index.js';
import { type ButtonProps, Button } from '../button/index.js';
import { LoadingIcon } from '../icons/loading-icon.js';
import { PlayIcon } from '../icons/play-icon.js';
import { ShuffleIcon } from '../icons/shuffle-icon.js';
import { Text } from '../text/index.js';
import { Tooltip } from '../tooltip/index.js';

type PlayerPlayProps = SetOptional<
  ButtonProps,
  'color' | 'kind' | 'size' | 'children'
> & {
  loading?: boolean;
  tooltip?: string;
  shuffle?: boolean;
};

const PlayContainer: ComponentType<BoxProps> = Box;
PlayContainer.displayName = 'Player.Play.PlayButtonContainer';

const defaultColors = { dark: 'white', light: 'gray' } as const;

export const PlayerPlay = forwardRef<HTMLButtonElement, PlayerPlayProps>(
  function PlayerPlay(
    {
      color = defaultColors,
      loading = false,
      disabled = false,
      children,
      tooltip = 'Play',
      shuffle = false,
      ...props
    }: PlayerPlayProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    if (!children) {
      children = (
        <PlayIcon
          css={{
            height: '4rem',
          }}
        />
      );
    }

    return (
      <PlayContainer
        css={{
          [`@container ${media.large}`]: { display: 'contents' },

          '[data-player-type="fullscreen"] &': {
            '@xsmall': { display: 'contents' },
            '@large': { display: 'none' },
          },
        }}
        gridArea="play"
        justifySelf="center"
        position="relative"
        zIndex="$9"
      >
        <Tooltip
          side="top"
          trigger={
            <>
              <Button
                disabled={disabled}
                {...props}
                aria-label="Play"
                color={color}
                css={{
                  boxShadow: '$elevation-1',
                }}
                kind="primary"
                ref={ref}
                size="icon"
              >
                {loading ?
                  <LoadingIcon
                    css={{
                      height: '100%',
                      left: 0,
                      pointerEvents: 'none',
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      zIndex: '$1',
                    }}
                    fill="gray-300"
                  />
                : null}
                {children}
              </Button>
              {shuffle ?
                <Box
                  alignItems="center"
                  backgroundColor="$brand-white"
                  borderRadius="$999"
                  bottom="$0"
                  boxShadow="$elevation-1"
                  display="flex"
                  height="2.4rem"
                  justifyContent="center"
                  position="absolute"
                  right="$0"
                  transition="all 200ms ease"
                  width="2.4rem"
                  zIndex="1000"
                >
                  <ShuffleIcon fill="blue-500" size="16" />
                </Box>
              : null}
            </>
          }
        >
          <Text
            color={{
              dark: '$brand-black',
              light: '$brand-white',
            }}
            kind="caption-3"
          >
            {tooltip}
          </Text>
        </Tooltip>
      </PlayContainer>
    );
  },
);

PlayerPlay.displayName = 'Player.Play';
