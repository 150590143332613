import { Button } from '@iheartradio/web.accomplice/button';
import { Group } from '@iheartradio/web.accomplice/group';
import {
  Facebook,
  FacebookColor,
  Google,
  GoogleColor,
} from '@iheartradio/web.accomplice/icons';
import { Separator } from '@iheartradio/web.accomplice/separator';
import { Stack } from '@iheartradio/web.accomplice/stack';
import { Text } from '@iheartradio/web.accomplice/text';
import { useSearchParams, useSubmit } from '@remix-run/react';
import type React from 'react';
import { useEffect, useMemo } from 'react';

import { useConfig } from '~app/contexts/config';
import { facebookSdkFactory, googleSdkFactory } from '~app/utilities/social';
import type { OauthLoginActionBody } from '~app/utilities/social/types';

export type SocialLoginParams = {
  disabled?: boolean;
  action?: '/social/login?index' | '/oauth2/v1/authorize';
  setProvider: React.Dispatch<
    React.SetStateAction<'Facebook' | 'Google' | null>
  >;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
  redirect_uri?: string;
  client_id?: string;
  fpHash?: string;
};

const buildFormData = (
  data: OauthLoginActionBody,
  skipAccountCheck: boolean,
  redirectUrl: string | null,
  redirect_uri?: string,
  client_id?: string,
  fpHash?: string,
): FormData => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, String(value));
  }
  if (skipAccountCheck) formData.append('skipCheck', 'true');
  if (redirectUrl) formData.append('redirectUrl', redirectUrl);
  if (redirect_uri) formData.append('redirect_uri', redirect_uri);
  if (client_id) formData.append('client_id', client_id);
  if (fpHash) formData.append('fpHash', fpHash);

  return formData;
};

export const SocialLogin = ({
  action = '/social/login?index',
  client_id,
  disabled = false,
  fpHash,
  redirect_uri,
  setProvider,
  setShowNotification,
}: SocialLoginParams) => {
  const config = useConfig();
  const submit = useSubmit();
  const [searchParams] = useSearchParams();
  const skipAccountCheck = searchParams.get('skipAccountCheck') === 'true';
  const redirectUrl = searchParams.get('redirectUrl');

  const googleSdk = useMemo(
    () =>
      googleSdkFactory({
        onLogin: postBody => {
          submit(
            buildFormData(
              postBody,
              skipAccountCheck,
              redirectUrl,
              redirect_uri,
              client_id,
              fpHash,
            ),
            {
              action,
              method: 'post',
            },
          );
        },
        onError: () => {
          setProvider('Google');
          setShowNotification(true);
        },
      }),
    [
      action,
      submit,
      skipAccountCheck,
      setProvider,
      setShowNotification,
      redirectUrl,
      redirect_uri,
      client_id,
      fpHash,
    ],
  );

  const facebookSdk = useMemo(
    () =>
      facebookSdkFactory({
        onLogin: postBody => {
          submit(
            buildFormData(
              postBody,
              skipAccountCheck,
              redirectUrl,
              redirect_uri,
              client_id,
              fpHash,
            ),
            {
              action,
              method: 'post',
            },
          );
        },
        onError: () => {
          setProvider('Facebook');
          setShowNotification(true);
        },
      }),
    [
      action,
      submit,
      skipAccountCheck,
      setProvider,
      setShowNotification,
      redirectUrl,
      redirect_uri,
      client_id,
      fpHash,
    ],
  );

  // Initialize facebookSdk and googleSdk on mount, and remove them on un-mount
  useEffect(() => {
    if (!facebookSdk.initialized && config.sdks.facebook?.appId) {
      facebookSdk.initialize(config.sdks.facebook?.appId);
    }
    if (!googleSdk.initialized && config.sdks.googlePlus?.appKey) {
      googleSdk.initialize(config.sdks.googlePlus?.appKey);
    }

    return () => {
      googleSdk.remove();
      facebookSdk.remove();
    };
  }, [
    config.sdks.facebook?.appId,
    config.sdks.googlePlus?.appKey,
    facebookSdk,
    googleSdk,
  ]);

  if (!(config.sdks.facebook?.appId || config.sdks.googlePlus?.appKey))
    return null;

  return (
    <Stack
      css={{
        paddingX: '$24',
        paddingTop: '$16',
        paddingBottom: '$24 ',
        textAlign: 'center',
      }}
      gap="$16"
    >
      <Group align="center" gap="$16">
        <Separator />
        <Text kind="subtitle-3">OR</Text>
        <Separator />
      </Group>
      <Button
        aria-label="Continue with Facebook"
        color="white"
        data-test="fb-button"
        inline
        isDisabled={disabled}
        kind="secondary"
        onPress={facebookSdk.login}
        size="large"
      >
        <Group align="center" gap="$8">
          {disabled ?
            <Facebook />
          : <FacebookColor />}
          Continue with Facebook
        </Group>
      </Button>
      <Button
        aria-label="Continue with Google"
        color="white"
        data-test="google-button"
        inline
        isDisabled={disabled}
        kind="secondary"
        onPress={googleSdk.login}
        size="large"
      >
        <Group align="center" gap="$8">
          {disabled ?
            <Google />
          : <GoogleColor />}
          Continue with Google
        </Group>
      </Button>
    </Stack>
  );
};
