import type { ForwardedRef, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import type { RainbowSprinkles } from '../../rainbow-sprinkles.css.js';
import type { ElementProps } from '../../types.js';
import { getRecipeStyleProps } from '../../utilities/get-recipe-style-props.js';
import { type LogoFill, type LogoSizes, logoStyles } from './logo.css.js';

export type LogoProps = PropsWithChildren<{
  'aria-label'?: string;
  css?: RainbowSprinkles;
  id?: string;
  fill?: LogoFill;
  size?: LogoSizes;
  media?: string;
  mode?: 'light' | 'dark';
}> &
  ElementProps<'svg'>;

function Logo(
  { 'aria-label': ariaLabel, children, css, id, ...props }: LogoProps,
  ref: ForwardedRef<SVGSVGElement>,
) {
  const { className, style, otherProps } = getRecipeStyleProps(
    logoStyles,
    {
      ...props,
    },
    {
      width: 'auto',
      ...css,
    },
  );

  return (
    <svg
      aria-labelledby={id}
      className={className}
      ref={ref}
      role="img"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <title id={id}>{ariaLabel}</title>
      {children}
    </svg>
  );
}

export const _Logo = forwardRef(Logo);
export { _Logo as Logo };
