import { type IconProps, Icon } from './icon.js';

export function VisibilityOn(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Visibility On" {...props}>
      <svg viewBox="0 0 24 25">
        <path d="M12 5.44c-5 0-9.27 3.1-11 7.5a11.83 11.83 0 0 0 22 0c-1.73-4.4-6-7.5-11-7.5Zm0 12.5a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-8a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" />
      </svg>
    </Icon>
  );
}
