import { type ReactNode, forwardRef } from 'react';

import type { Component } from '../../types.js';
import { type LinkProps, Link } from '../link/index.js';
import { useFullScreen } from './player-root.js';

/**
 * This component needs to be used wherever there are functional links while the FSP may be open
 * This link abstraction forces the FSP to close when interacting with these links
 */
export type PlayerLinkProps = LinkProps & {
  children: ReactNode;
  onClick?: () => void;
};

export const PlayerLink = forwardRef<HTMLAnchorElement, PlayerLinkProps>(
  function PlayerLink({ children, onClick, ...props }: PlayerLinkProps, ref) {
    const [, setIsFullScreen] = useFullScreen();

    return (
      <Link
        {...props}
        onClick={() => {
          setIsFullScreen(false);
          onClick?.();
        }}
        ref={ref}
        underline="hover"
      >
        {children}
      </Link>
    );
  },
) as Component<'a', PlayerLinkProps>;
