import {
  RadioButton,
  RadioGroup,
} from '@iheartradio/web.accomplice/radio-group';
import type { ReactNode } from 'react';

import { useConfig } from '~app/contexts/config';

export type GenderRadioGroupProps = {
  disabled?: boolean;
  error?: ReactNode;
  hint?: string;
  required?: boolean;
  defaultValue?: string;
  name: string;
  message?: ReactNode;
};

export const GenderRadioGroup = ({
  disabled = false,
  hint,
  required = false,
  defaultValue,
  name,
  message,
}: GenderRadioGroupProps) => {
  const config = useConfig();
  const genders = Object.entries(config.account.registration.genders ?? []).map(
    (entry: [string, { label: string }]) => {
      return {
        value: entry[0],
        label: entry[1].label,
      };
    },
  );

  return (
    <RadioGroup
      defaultValue={defaultValue}
      hint={hint}
      id="gender"
      isDisabled={disabled}
      isRequired={required}
      label="Gender"
      message={message}
      name={name}
      orientation="horizontal"
    >
      {genders.map(option => {
        return (
          <RadioButton
            data-test={`gender-${option.value}`}
            id={`gender-${option.value}`}
            isDisabled={disabled}
            key={option.label}
            value={option.value}
          >
            {option.label}
          </RadioButton>
        );
      })}
    </RadioGroup>
  );
};
