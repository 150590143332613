import { forwardRef } from 'react';

import { lightDark } from '../../media.js';
import { vars } from '../../theme-contract.css.js';
import { type LogoProps, Logo } from './logo.js';

export const LogotypeBeta = forwardRef<SVGSVGElement, LogoProps>(
  function LogotypeSecondary(
    {
      'aria-label': ariaLabel = 'Secondary Dark Logotype',
      children: _children,
      fill: _fill,
      size = 24,
      media = 'mobile',
      ...props
    }: LogoProps,
    ref,
  ) {
    return (
      <Logo
        aria-label={ariaLabel}
        ref={ref}
        size={size}
        viewBox="0 0 142 24"
        {...props}
      >
        <>
          <path
            d="M37.943 6.71c0-1.295 1.062-2.332 2.39-2.332 1.297 0 2.389 1.037 2.389 2.333 0 1.296-1.062 2.333-2.39 2.333-1.327 0-2.389-1.037-2.389-2.333Zm6.815-1.151h4.602v6.077h5.9V5.559h4.602v16.215H55.26V15.61h-5.9v6.164h-4.602V5.56Zm16.431 9.994v-.058c0-3.657 2.685-6.566 6.49-6.566 4.425 0 6.49 3.168 6.49 6.854 0 .289 0 .605-.03.893h-8.584c.354 1.354 1.387 2.074 2.773 2.074 1.062 0 1.918-.403 2.862-1.267l2.507 2.016c-1.239 1.526-3.009 2.563-5.575 2.563-4.071 0-6.933-2.62-6.933-6.509Zm8.732-1.152c0-1.354-1.003-2.218-2.242-2.218-1.18 0-2.124.893-2.242 2.218h4.484Zm23.925-5.156h-4.514v12.53h4.514v-3.89c0-2.88 1.327-4.204 3.687-4.204h.384V9.044c-2.154-.087-3.334 1.008-4.071 2.736V9.245Zm6.873 8.64v-5.097h-1.504V9.245h1.504V6.077h4.514v3.168h2.98v3.543h-2.98v4.234c0 .893.413 1.238 1.239 1.238.59 0 1.151-.173 1.711-.403v3.456c-.796.432-1.888.72-3.127.72-2.714 0-4.337-1.152-4.337-4.147Zm-13.127-2.418c0-3.889-2.862-6.51-6.874-6.51-2.566 0-4.336 1.009-5.575 2.564l2.507 2.016c.915-.893 1.77-1.267 2.862-1.267 1.386 0 2.39.69 2.773 2.016v.086h-2.98c-3.687.058-5.634 1.383-5.634 3.946 0 2.448 1.8 3.773 4.867 3.773 1.682 0 2.95-.634 3.806-1.498v1.21h4.277v-6.336h-.03Zm-6.756 3.801c-1.18.058-1.858-.604-1.858-1.41 0-.779.62-1.153 1.74-1.153h2.567v.144c0 .518-.207 2.333-2.449 2.42Zm-40.621-9.13h.176c1.299 0 2.36 1.037 2.331 2.304v9.332h-4.867v-9.332c0-1.267 1.062-2.304 2.36-2.304Z"
            fill={lightDark(vars.color['brandBlack'], vars.color['brandWhite'])}
          />
          <path
            d="M24.58 0c-3.128 0-5.93 1.613-7.582 4.003C15.374 1.642 12.571 0 9.415 0 4.4 0 .359 3.946.359 8.842c0 3.11 2.006 5.328 4.1 7.402l8.202 7.575c.443.403 1.15.086 1.15-.49v-5.904c0-1.7 1.416-3.082 3.157-3.082 1.74 0 3.157 1.383 3.157 3.082v5.904c0 .576.707.864 1.15.49l8.201-7.575c2.095-2.074 4.1-4.291 4.1-7.402C33.637 3.946 29.595 0 24.58 0ZM8.795 15.668a.593.593 0 0 1-.472.202.716.716 0 0 1-.413-.144c-2.714-2.333-4.07-4.781-4.07-7.258v-.03c0-2.13 1.327-4.55 3.097-5.615a.63.63 0 0 1 .855.201.599.599 0 0 1-.206.835c-1.416.864-2.508 2.88-2.508 4.58v.029c0 2.102 1.24 4.233 3.658 6.336.266.202.295.605.06.864Zm4.042-2.275a.635.635 0 0 1-.56.317.507.507 0 0 1-.296-.087c-1.829-1.008-3.068-2.966-3.068-4.896v-.029c0-1.843 1.033-3.514 2.715-4.378a.679.679 0 0 1 .855.26c.148.287.03.662-.265.835a3.654 3.654 0 0 0-2.036 3.283c0 1.498.973 3.053 2.419 3.83.295.173.383.548.236.865Zm4.16-1.584c-1.505 0-2.715-1.181-2.715-2.65 0-1.469 1.21-2.65 2.714-2.65 1.505 0 2.715 1.181 2.715 2.65.029 1.469-1.21 2.65-2.715 2.65Zm5.044 1.814a.58.58 0 0 1-.295.087.672.672 0 0 1-.56-.317c-.178-.288-.06-.663.235-.836 1.446-.806 2.42-2.332 2.42-3.83 0-1.383-.797-2.65-2.036-3.283-.295-.144-.413-.519-.265-.836.147-.288.53-.403.855-.259 1.682.864 2.714 2.535 2.714 4.378v.029c0 1.9-1.239 3.86-3.068 4.867Zm8.142-5.184c0 2.477-1.357 4.925-4.07 7.258-.119.115-.266.144-.414.144a.593.593 0 0 1-.472-.202.592.592 0 0 1 .06-.864c2.418-2.102 3.657-4.234 3.657-6.336V8.41c0-1.728-1.091-3.744-2.507-4.58a.599.599 0 0 1-.207-.835.63.63 0 0 1 .856-.201c1.77 1.094 3.097 3.514 3.097 5.616v.029Z"
            fill={vars.color['red600']}
          />
          <rect
            fill={`url(#paint0_linear_8732_84230_${media})`}
            height="12"
            rx="2"
            width="29"
            x="112.571"
            y="12"
          />
          <path
            d="M115.621 20.442v-5.184a.55.55 0 0 1 .558-.558h2.268c.72 0 1.287.198 1.647.558.279.279.423.621.423 1.044v.018c0 .756-.432 1.161-.9 1.404.738.252 1.251.675 1.251 1.53v.018c0 1.125-.927 1.728-2.331 1.728h-2.358a.55.55 0 0 1-.558-.558Zm1.089-3.087h1.512c.711 0 1.188-.279 1.188-.855v-.018c0-.495-.396-.801-1.107-.801h-1.593v1.674Zm0 2.664h1.836c.756 0 1.215-.297 1.215-.864v-.018c0-.531-.423-.846-1.296-.846h-1.755v1.728Zm5.938.981a.55.55 0 0 1-.558-.558v-5.184a.55.55 0 0 1 .558-.558h3.663a.5.5 0 0 1 .495.495.494.494 0 0 1-.495.495h-3.114v1.638h2.709c.27 0 .495.225.495.504 0 .27-.225.486-.495.486h-2.709v1.692h3.159a.5.5 0 0 1 .495.495.494.494 0 0 1-.495.495h-3.708Zm6.992-.504v-4.77h-1.53a.513.513 0 1 1 0-1.026h4.185a.51.51 0 0 1 .504.513.51.51 0 0 1-.504.513h-1.539v4.77a.553.553 0 0 1-.558.549.553.553 0 0 1-.558-.549Zm3.108.036c0-.081.027-.162.072-.252l2.34-5.22c.126-.279.351-.45.666-.45h.054c.315 0 .531.171.657.45l2.34 5.22a.519.519 0 0 1-.459.765c-.261 0-.441-.153-.54-.387l-.513-1.179h-3.06l-.531 1.215a.532.532 0 0 1-.513.351.507.507 0 0 1-.513-.513Zm1.98-2.034h2.214l-1.107-2.547-1.107 2.547Z"
            fill={vars.color['brandWhite']}
          />
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id={`paint0_linear_8732_84230_${media}`}
              x1="112.571"
              x2="116.113"
              y1="12"
              y2="29.138"
            >
              <stop stopColor={vars.color['blue600']} />
              <stop offset="1" stopColor={vars.color['blue550']} />
            </linearGradient>
          </defs>
        </>
      </Logo>
    );
  },
) as typeof Logo;
