export { CCPAUserPrivacy } from './ccpa-user-privacy.js';
export { arrayCompare } from './compare.js';
export { fetchWithTimeout } from './fetch-with-timeout.js';
export {
  isBlank,
  isEmptyCollection,
  isEmptyMap,
  isEmptySet,
  isFalsy,
  isInvalidDate,
  isNotBlank,
  isWhitespaceString,
  isZeroLengthString,
} from './guards/index.js';
export { hashText } from './hash-text.js';
export { identity, identityP } from './identity.js';
export { isBase64, isJWT, isLocale } from './is/index.js';
export { jsonToStringForHeader } from './json-to-string-for-header.js';
export { kebabCase } from './kebab-case.js';
export { loadScript } from './load-script/index.js';
export {
  type ImageOperation,
  type MediaServerImage,
  mediaServerImage,
} from './media-server/index.js';
export {
  type SafeInterval,
  createSafeInterval,
} from './safe-interval/index.js';
export { createSafeTimeout } from './safe-timeout/index.js';
export * from './script.js';
export { slugify } from './slugify.js';
export { toHHMMSS } from './time/index.js';
export * from './typeof/index.js';
export * from './url.js';
export { waitUntil } from './wait-until/index.js';
export { default as debounce } from 'debounce';
export { dset } from 'dset';
export { default as cookie } from 'js-cookie';
export { default as memoize } from 'mem';
export { default as throttle } from 'throttleit';
export { v4 as uuid } from 'uuid';

export function prune(str: string, length: number, end: string = '...') {
  if (length == null || length >= str.length) {
    return str;
  }
  const remnantPlusOne = str.slice(0, Math.max(0, length - end.length) + 1);
  const lastSpace = Math.max(0, remnantPlusOne.lastIndexOf(' '));
  return remnantPlusOne.slice(0, lastSpace) + end;
}
