import { Box } from '@iheartradio/web.accomplice/box';
import { Button } from '@iheartradio/web.accomplice/button';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Pencil } from '@iheartradio/web.accomplice/icons';
import { Notification } from '@iheartradio/web.accomplice/notification';
import { ACCOUNT_CONSTANTS } from '@iheartradio/web.config';
import {
  useActionData,
  useLoaderData,
  useSearchParams,
  useSubmit,
} from '@remix-run/react';
import { withZod } from '@remix-validated-form/with-zod';
import { useEffect, useState } from 'react';
import { ValidatedForm } from 'remix-validated-form';
import { z } from 'zod';

import { usePageView } from '~app/analytics/use-page-view';
import { EmailOptIn } from '~app/components/email-opt-in';
import {
  GenderRadioGroup,
  PasswordField,
  SubmitButton,
  TextField,
} from '~app/components/input-fields/input-fields';
import { PasswordChecks } from '~app/components/password-checks';
import { PrivacyTermsOptout } from '~app/components/privacy-terms-optout';
import { SIGNUP_EMAIL as VALIDATION_ERRORS } from '~app/constants/validation-errors';
import {
  birthYearSchema,
  genderSchema,
  getZipCodeSchema,
  passwordSchema,
} from '~app/constants/zod-validations';
import { useFingerprint, usePasswordValidation } from '~app/hooks';

import type { EmailSignUpActionData } from './.server/action';
import type { EmailSignUpLoader } from './.server/loader';

export const BirthYearHint =
  'Your birth year helps us personalize your iHeart experience.';
export const ZipCodeHint =
  'Your location helps us personalize your live radio recommendations.';
export const GenderHint =
  'Your gender helps us personalize your iHeart experience.';

export const getValidator = (zipRegex: string = '') =>
  withZod(
    z.object({
      password: passwordSchema,
      birthYear: birthYearSchema,
      zipCode: getZipCodeSchema(zipRegex),
      gender: genderSchema,
    }),
  );

// FIXME: This type is a workaround - need to refine the action a bit to get rid of this
type ActionData =
  | Exclude<EmailSignUpActionData, { fieldErrors: unknown }>
  | undefined;

export default function SignUpEmail() {
  const submit = useSubmit();
  const actionData: ActionData = useActionData();
  const errors = actionData?.errors ?? {};

  const {
    zipKeyboard,
    zipRegex,
    unsupportedCountry,
    genders,
    signupDisallowed: _signupDisallowed,
    pageName,
  } = useLoaderData<EmailSignUpLoader>();

  const [showError, setShowError] = useState(true);
  const [passwordServerValidationError, setPasswordServerValidationError] =
    useState<string | null>();
  const [searchParams] = useSearchParams();
  const userName = searchParams.get('userName') ?? actionData?.userName;
  const readonlyUserName = !!userName;
  const redirectUrl =
    searchParams.get('redirectUrl') ?? actionData?.redirectUrl;
  const redirectUri = searchParams.get('redirect_uri');
  const clientId = searchParams.get('client_id');
  const signupDisallowed = actionData?.signupDisallowed || _signupDisallowed;
  const { fpHash } = useFingerprint();
  const { passwordValidationResults, doPasswordChecks } =
    usePasswordValidation();

  usePageView(pageName);

  const validator = getValidator(zipRegex ?? '');

  const returnSearchParams = new URLSearchParams();
  if (userName) {
    returnSearchParams.append('userName', userName);
  }
  if (redirectUrl) {
    returnSearchParams.append('redirectUrl', redirectUrl);
  }
  const isUnsupportedCountry =
    unsupportedCountry ||
    errors?.unsupportedCountry === VALIDATION_ERRORS.UNSUPPORTED_COUNTRY;

  useEffect(() => {
    if (errors?.password?.[0]) {
      setPasswordServerValidationError(errors?.password?.[0]);
    }
  }, [errors?.password]);

  return (
    <ValidatedForm
      id="signup-form"
      method="POST"
      onSubmit={async () => {
        submit({});
      }}
      replace
      validator={validator}
    >
      <Flex
        data-test="sign-up-email-container"
        direction="column"
        gap="$32"
        paddingBottom="$24"
        paddingTop="0"
        paddingX="$24"
      >
        {showError && errors?.other ?
          <Notification
            data-test="error-notification"
            kind="error"
            onDismiss={() => setShowError(false)}
            title={errors?.other?.title}
          >
            {errors?.other?.error}
          </Notification>
        : null}
        {signupDisallowed ?
          <Notification
            data-test="sign-up-disallowed-notification"
            kind="error"
            title="Registration error"
          >
            Sorry, you are not eligible to register for iHeart
          </Notification>
        : null}
        {isUnsupportedCountry ?
          <Notification
            data-test="unsupported-country-notification"
            kind="error"
            title="Unsupported region"
          >
            {VALIDATION_ERRORS.UNSUPPORTED_COUNTRY}
          </Notification>
        : null}
        <Box position="relative">
          <TextField
            autoComplete="username"
            defaultValue={userName ?? ''}
            disabled={
              readonlyUserName || signupDisallowed || isUnsupportedCountry
            }
            label="Email"
            message={errors?.userName}
            name="userName"
            placeholder="Enter email address"
            type="email"
          />
          {readonlyUserName ?
            <input name="userName" type="hidden" value={userName} />
          : null}
          {readonlyUserName ?
            <Button
              color="white"
              css={{ position: 'absolute', right: 0, bottom: '0.4rem' }}
              data-test="email-edit-icon"
              href={`/sign-up?${returnSearchParams.toString()}`}
              kind="tertiary"
              size="icon"
              title="Edit email address"
            >
              <Pencil size={16} />
            </Button>
          : null}
        </Box>
        <PasswordField
          autoComplete="off"
          defaultValue={actionData?.password}
          description={
            <PasswordChecks
              passwordValidationResults={passwordValidationResults}
              serverValidationError={passwordServerValidationError}
            />
          }
          isDisabled={signupDisallowed || isUnsupportedCountry}
          label="Password"
          name="password"
          onChange={(value: string) => doPasswordChecks(value)}
          onFocus={() => setPasswordServerValidationError(null)}
          placeholder="Enter password"
        />
        <Flex
          direction={{
            mobile: 'column',
            xsmall: 'column',
            small: 'row',
          }}
          gap="$32"
        >
          <TextField
            data-test="birth-year"
            disabled={signupDisallowed || isUnsupportedCountry}
            hint={BirthYearHint}
            isClearable
            label="Birth Year"
            name="birthYear"
            placeholder="Enter birth year"
          />
          <TextField
            data-test="zip-code"
            disabled={signupDisallowed || isUnsupportedCountry}
            hint={ZipCodeHint}
            inputMode={
              zipKeyboard === ACCOUNT_CONSTANTS.KEYBOARD_NUMERIC ?
                'numeric'
              : 'text'
            }
            isClearable
            label="Zip Code"
            name="zipCode"
            placeholder="Enter zip code"
          />
        </Flex>
        {redirectUrl ?
          <input name="redirectUrl" type="hidden" value={redirectUrl} />
        : null}
        {redirectUri ?
          <input name="redirectUri" type="hidden" value={redirectUri} />
        : null}
        {clientId ?
          <input name="clientId" type="hidden" value={clientId} />
        : null}

        <GenderRadioGroup
          defaultValue={actionData?.gender}
          disabled={signupDisallowed || isUnsupportedCountry}
          genders={genders}
          hint={GenderHint}
          label="Gender"
          name="gender"
        />
        <EmailOptIn disabled={signupDisallowed || isUnsupportedCountry} />
        <PrivacyTermsOptout />
        <input name="fpHash" type="hidden" value={fpHash} />
        <SubmitButton
          disabled={signupDisallowed || isUnsupportedCountry}
          label="Sign up for free"
          onClick={() => {
            setShowError(true);
          }}
        />
      </Flex>
    </ValidatedForm>
  );
}
