import type { ReactNode } from 'react';
import type { CheckboxProps as RACCheckboxProps } from 'react-aria-components';
import { Checkbox as RACCheckbox } from 'react-aria-components';

import { CheckboxChecked } from '../../icons/checkbox-checked.js';
import { CheckboxHalfChecked } from '../../icons/checkbox-half-checked.js';
import { CheckboxUnchecked } from '../../icons/checkbox-unchecked.js';
import { checkboxStyles, iconStyles, racStyles } from './checkbox.css.js';

export interface CheckboxProps extends RACCheckboxProps {
  children?: ReactNode;
}

export function Checkbox({ children, ...props }: CheckboxProps) {
  const { defaultSelected, isDisabled, ...restProps } = props;

  return (
    <RACCheckbox
      className={racStyles}
      data-test="checkbox"
      defaultSelected={defaultSelected}
      isDisabled={isDisabled}
      {...restProps}
    >
      {({ isIndeterminate, isSelected }) => (
        <>
          <div className={checkboxStyles}>
            {isIndeterminate ?
              <CheckboxHalfChecked
                className={iconStyles}
                size={{ mobile: 16, large: 24 }}
              />
            : isSelected ?
              <CheckboxChecked
                className={iconStyles}
                size={{ mobile: 16, large: 24 }}
              />
            : <CheckboxUnchecked
                className={iconStyles}
                size={{ mobile: 16, large: 24 }}
              />
            }
          </div>
          {children}
        </>
      )}
    </RACCheckbox>
  );
}
