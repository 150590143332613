/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createContext, useContext } from 'react';

export const ThemeEnum = {
  light: 'light',
  dark: 'dark',
} as const;

export type Theme = keyof typeof ThemeEnum;

export const ThemeContext = createContext<Theme | null>(null);
export const ThemeProvider = ThemeContext.Provider;

export function useTheme(): Theme {
  const contextValue = useContext(ThemeContext);

  if (!contextValue) {
    /**
     * I'm not positive how "safe" this is long-term... but it should be fine for now.
     *
     * It is important for this hook to throw an error if there's no theme provided at runtime in an
     * app but in test environments, it's mostly fine to rely on a fallback so we don't have to wrap
     * every test with ThemeProvider. That's probably the better long-term solution, using existing
     * patterns for custom render functions in the `test-util.tsx` file.
     */
    if (
      // @ts-ignore
      import.meta?.env?.MODE === 'test' ||
      // @ts-ignore
      import.meta?.env?.TEST === 'true'
    ) {
      // In test environments, we can default to 'light'
      return 'light';
    }

    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return contextValue;
}
