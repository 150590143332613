import { forwardRef } from 'react';

import { lightDark } from '../../media.js';
import { type LogoProps, Logo } from './logo.js';

export const Logomark = forwardRef<SVGSVGElement, LogoProps>(
  function LogomarkBlack(
    {
      'aria-label': ariaLabel = 'Logomark',
      children: _children,
      size = 36,
      ...props
    }: LogoProps,
    ref,
  ) {
    return (
      <Logo
        aria-label={ariaLabel}
        css={{
          ...props.css,
          fill: props.css?.fill ?? lightDark('$gray600', '$brandWhite'),
        }}
        ref={ref}
        size={size}
        viewBox="0 0 46 34"
        {...props}
      >
        <path d="M32.787 0c-4.254 0-8.055 2.247-10.257 5.529C20.33 2.247 16.528 0 12.28 0a12.304 12.304 0 0 0-8.685 3.582A12.196 12.196 0 0 0 0 12.24c0 4.288 2.724 7.38 5.57 10.24l11.127 10.486a.916.916 0 0 0 1.542-.674v-8.165a4.27 4.27 0 0 1 1.257-3.024 4.298 4.298 0 0 1 6.069 0 4.27 4.27 0 0 1 1.256 3.024v8.165a.91.91 0 0 0 .553.838.918.918 0 0 0 .99-.175L39.491 22.47c2.845-2.857 5.575-5.948 5.575-10.23a12.196 12.196 0 0 0-3.594-8.656A12.305 12.305 0 0 0 32.787 0ZM11.39 21.667a.852.852 0 0 1-1.2.078c-3.666-3.239-5.524-6.618-5.524-10.046v-.045c0-2.946 1.804-6.296 4.194-7.79a.852.852 0 0 1 1.319.901.848.848 0 0 1-.415.53C7.858 6.488 6.367 9.28 6.367 11.65v.04c0 2.922 1.665 5.875 4.95 8.776a.85.85 0 0 1 .241.914.85.85 0 0 1-.169.287Zm5.47-3.145a.854.854 0 0 1-1.156.326c-2.482-1.388-4.158-4.111-4.169-6.786v-.026a6.823 6.823 0 0 1 .994-3.533 6.853 6.853 0 0 1 2.683-2.513.853.853 0 0 1 1.15.356.846.846 0 0 1-.358 1.145 5.155 5.155 0 0 0-2.022 1.894 5.133 5.133 0 0 0-.746 2.663c0 2.083 1.33 4.222 3.298 5.32a.848.848 0 0 1 .327 1.154Zm5.671-2.165a3.7 3.7 0 0 1-2.051-.62 3.669 3.669 0 0 1-.562-5.662 3.695 3.695 0 0 1 4.023-.798 3.69 3.69 0 0 1 1.657 1.355 3.672 3.672 0 0 1-.458 4.646 3.698 3.698 0 0 1-2.609 1.079Zm6.828 2.49a.853.853 0 0 1-1.149-.33.846.846 0 0 1 .318-1.149c1.969-1.098 3.293-3.235 3.299-5.32 0-.939-.259-1.86-.747-2.663a5.157 5.157 0 0 0-2.021-1.894.85.85 0 0 1-.452-.828.848.848 0 0 1 .925-.767c.111.01.22.042.318.094a6.853 6.853 0 0 1 2.682 2.51 6.824 6.824 0 0 1 .995 3.531v.026c-.01 2.68-1.683 5.403-4.168 6.79Zm11.038-7.153c0 3.428-1.859 6.807-5.524 10.045a.852.852 0 0 1-1.413-.686.846.846 0 0 1 .286-.583c3.285-2.901 4.95-5.86 4.95-8.776v-.04c0-2.371-1.493-5.163-3.396-6.355a.848.848 0 0 1 .905-1.432c2.39 1.495 4.195 4.845 4.195 7.79l-.003.037Z" />
      </Logo>
    );
  },
) as typeof Logo;
