import { type IconProps, Icon } from './icon.js';

export function CheckboxUnchecked(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Checkbox Unchecked" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M18 19H6a1 1 0 0 1-1-1V6c0-.5.5-1 1-1h12c.6 0 1 .5 1 1v12c0 .6-.4 1-1 1Zm1-16H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z" />
      </svg>
    </Icon>
  );
}
