/* eslint-disable unicorn/numeric-separators-style */
import type { BaseConfig, User } from '@iheartradio/web.config';
import {
  hashText,
  isNotBlank,
  loadScript,
  slugify,
} from '@iheartradio/web.utilities';

import type { Event, EventType } from '../../types.js';
import type { PageParams, PromoParams, ScriptParams } from './types.js';

let currentStationId = '';

export const getPromoId = ({
  isHome,
  isDirectory,
  isProfile,
  isLibrary,
}: PromoParams): number => {
  if (isHome) {
    return 1;
  } else if (isDirectory || isLibrary) {
    return 2;
  } else if (isProfile) {
    return 3;
  } else {
    return 4;
  }
};

export const getPageParameters = (event: Event['data']): PageParams | null => {
  let isProfile = false;
  let profileType: string | null | undefined = null;
  let isDirectory = false;
  let directoryType: string | null = null;
  let isHome = false;
  let isLibrary = false;
  let name = '';

  if (isNotBlank(event)) {
    if ('pageName' in event) {
      const { pageName } = event;

      isProfile = pageName.includes('profile');
      profileType = isProfile ? pageName.replace('_profile', '') : null;

      if (
        profileType &&
        ['song', 'episode', 'podcast_episode', 'album'].includes(profileType)
      ) {
        isProfile = false;
      }

      isDirectory = pageName.includes('browse');
      directoryType = isDirectory ? pageName.replace('browse_', '') : null;

      isHome = pageName === 'home';
      isLibrary = pageName.includes('library');

      if ('view' in event) {
        const { view } = event;

        if (view && 'asset' in view) {
          const { asset } = view;
          name = asset.name ?? '';

          if ('sub' in asset) {
            const { sub } = asset;
            if (sub && 'name' in sub) {
              name = sub.name ?? '';
            }
          }
        }
      }
    }

    if ('station' in event) {
      const { station } = event;
      if (station && 'asset' in station) {
        const { asset } = station;
        if (asset && 'type' in asset) {
          isProfile = true;
          profileType = asset.type;
          name = asset.name ?? '';

          if ('subtype' in asset) {
            profileType = asset.subtype;

            if ('subname' in asset && !!asset.subname) {
              name = asset.subname ?? '';
            }
          }
        }
      }
    }
    const promoId = getPromoId({ isHome, isDirectory, isProfile, isLibrary });

    return {
      profileType,
      isProfile,
      directoryType,
      isDirectory,
      isHome,
      isLibrary,
      promoId,
      name: slugify(name),
    };
  }

  return null;
};

export const getScriptParameters = async ({
  pageParameters,
  profileId,
  event,
  email,
  emailHash,
}: {
  pageParameters: PageParams;
  profileId?: number;
  event: EventType;
  email?: string | null;
  emailHash?: string | null;
}): Promise<ScriptParams> => {
  return {
    dtm_fid: event === 'page_view' ? 5610 : 5611,
    dtm_cid: 82943,
    dtm_cmagic: 'f2930d',
    dtm_promo_id:
      event === 'page_view' ? pageParameters.promoId
      : event === 'stream_start' ? 101
      : 941,
    ...(isNotBlank(profileId) ? { dtm_user_id: profileId } : {}),
    ...(isNotBlank(email) && !isNotBlank(emailHash) ?
      { dtm_email_hash: await hashText(email) }
    : isNotBlank(emailHash) ? { dtm_email_hash: emailHash }
    : {}),
    ...(pageParameters.isDirectory && event === 'page_view' ?
      {
        dtmc_department: pageParameters.directoryType ?? pageParameters.name,
      }
    : {}),
    ...(pageParameters.isProfile && event === 'page_view' ?
      {
        dtmc_category: pageParameters.name,
      }
    : {}),
    ...((
      pageParameters.isProfile &&
      isNotBlank(pageParameters.profileType) &&
      event === 'page_view'
    ) ?
      {
        dtmc_department: pageParameters.profileType,
      }
    : {}),
    dtm_loc: encodeURIComponent(window.location.href),
    cachebuster: Date.now(),
  };
};

export const getScriptUrl = async (
  data: Event,
  config: BaseConfig,
  user?: User | null,
): Promise<string | null> => {
  const pageParameters = data && getPageParameters(data?.data);

  if (pageParameters) {
    const { profileId, emailHashes } =
      isNotBlank(user) ? user
      : data && data.data && 'profileId' in data.data ?
        {
          profileId: Number(data.data.profileId),
          emailHashes: [],
        }
      : { profileId: undefined, emailHashes: [] };
    const scriptParameters = await getScriptParameters({
      pageParameters,
      profileId,
      event: data.type,
      emailHash: emailHashes?.[2],
    });

    if (isNotBlank(config.urls.epsilon)) {
      const scriptTagUrl = new URL(config.urls.epsilon);
      for (const [key, value] of Object.entries(scriptParameters)) {
        scriptTagUrl.searchParams.set(key, String(value));
      }

      return scriptTagUrl.toString();
    }
  }

  return null;
};

export const doEpsilonTrack = async ({
  data,
  user,
  config,
}: {
  data: Event;
  user?: User | null;
  config: BaseConfig;
}): Promise<void> => {
  const { id } = getScriptId(data);

  if (id) {
    const scriptUrl = await getScriptUrl(data, config, user);

    if (scriptUrl) {
      loadScript(scriptUrl, {
        async: true,
        id,
        replace: true,
        target: document.body,
      });
    }
  }
};

export const getScriptId = (event: Event): { id: string | null } => {
  switch (event.type) {
    case 'page_view': {
      return { id: 'epsilon-page-view' };
    }
    case 'stream_start': {
      const { asset } = event.data.station;

      if ('id' in asset && currentStationId !== String(asset.id)) {
        currentStationId = String(asset.id);
        return { id: 'epsilon-stream-start' };
      } else if ('subid' in asset && currentStationId !== String(asset.subid)) {
        currentStationId = String(asset.subid);
        return { id: 'epsilon-stream-start' };
      }
      console.warn(`Epsilon stream_start event has no asset or sub-asset id`);
      return { id: null };
    }
    case 'post_login':
    case 'post_registration': {
      return { id: 'epsilon-auth-event' };
    }
    default: {
      console.warn(`Unmapped Epsilon event: ${event.type}`);
      return { id: null };
    }
  }
};
