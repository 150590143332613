import * as Dialog from '@radix-ui/react-dialog';

import { keyframes, styled } from '../../stitches.config.js';
// Animations
const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

// Styles
export const Root = styled(Dialog.Root);
export type { DialogProps } from '@radix-ui/react-dialog';

Root.displayName = 'Dialog.Root';

/**
 * @deprecated
 */
export const Trigger = styled(Dialog.Trigger, {});

Trigger.displayName = 'Dialog.Trigger';

export const Portal = styled(Dialog.Portal, {});

Portal.displayName = 'Dialog.Portal';

export const Content = styled(Dialog.Content, {
  backgroundColor: '$gray-600',
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: '35.5rem',
  borderRadius: '$6',
  scrollbarWidth: 'thin',

  dark: {
    scrollbarColor: '$gray-300 $gray-450',
  },
  light: {
    backgroundColor: '$brand-white',
    scrollbarColor: '$gray-500 $gray-250',
  },

  '@large': {
    width: '40.1rem',
  },
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 350ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  '&:focus': { outline: 'none' },

  '& > div': {
    /* Width */
    '&::-webkit-scrollbar': {
      width: '1.2rem',
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      borderRadius: '0 0 $6 0',

      dark: {
        backgroundColor: '$gray-450',
      },

      light: {
        backgroundColor: '$gray-250',
      },
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '$999',
      border: '2px solid transparent',
      backgroundClip: 'content-box',

      dark: {
        backgroundColor: '$gray-300',
      },

      light: {
        backgroundColor: '$gray-500',
      },
    },
  },
});

Content.displayName = 'Dialog.Content';

export const Close = styled(Dialog.Close, {
  position: 'absolute',
  top: '-$48',
  right: '0',
  padding: '$8',
  backgroundColor: 'transparent',
  '&:focus-visible': {
    dark: {
      outline: 'none',
    },
    light: {
      outline: 'none',
    },
  },
});

Close.displayName = 'Dialog.CloseButton';

export const Overlay = styled(Dialog.Overlay, {
  background: 'rgba(0 0 0 / 0.8)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'grid',
  placeItems: 'center',
  overflowY: 'auto',
  inset: 0,
  zIndex: '$10',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 350ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
});

Overlay.displayName = 'Dialog.Overlay';
