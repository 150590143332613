import { isNumber } from 'remeda';

import type { MediaValue } from '../../types.js';

export type SlideAmount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type SlidesToShow = SlideAmount | MediaValue<SlideAmount>;

export type EnabledType = {
  previous: boolean;
  next: boolean;
};

const peek = {
  '@initial': '5.6rem',
  '@xsmall': '5.6rem',
  '@small': '5.6rem',
  '@shmedium': '7.2rem',
  '@medium': '7.2rem',
  '@large': '8.8rem',
  '@xlarge': '8.8rem',
  '@xxlarge': '8.8rem',
} as const;

function computeWidth(
  amount: number,
  breakpoint: keyof typeof peek = '@initial',
) {
  function getWidth(offset: `${number}rem`) {
    return `calc(((100% - ${peek[breakpoint]}) / ${amount}) + ${offset})`;
  }

  const isLarge =
    breakpoint === '@large' ||
    breakpoint === '@xlarge' ||
    breakpoint === '@xxlarge';
  const width = getWidth('0rem');
  const firstChildWidth = getWidth(isLarge ? '1.6rem' : '0rem');

  return {
    [`& > *`]: {
      minWidth: width,
      paddingLeft: '$16',
      width,

      '&:first-of-type': {
        minWidth: firstChildWidth,
        paddingLeft: isLarge ? '$32' : undefined,
        width: firstChildWidth,
      },
    },

    '&::after': {
      content: `''`,
      display: 'flex',
      paddingLeft: isLarge ? '$32' : '$16',
    },
  };
}

export function computeSlideWidths(slidesToShow: SlidesToShow) {
  if (isNumber(slidesToShow)) {
    return computeWidth(slidesToShow, '@initial');
  }

  return Object.entries(slidesToShow).reduce((accumulator, [key, value]) => {
    const width = computeWidth(value, key as keyof typeof peek);

    return {
      ...accumulator,
      ...(key === '@initial' ? width : { [key]: width }),
    };
  }, {});
}
