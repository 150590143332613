import { type ReactNode, forwardRef } from 'react';
import type { ComboBoxProps } from 'react-aria-components';

import type { CSSProperties } from '../../types.js';
import { Field } from '../field/index.js';
import { Text } from '../text/index.js';
import { Button, Input, ListBox, Popover, Root } from './combobox.primitive.js';

export type ComboboxItem = { id: number; name: string };

// React Aria ComboBox Documentation: https://react-spectrum.adobe.com/react-aria/ComboBox.html#custom-filtering

export interface ComboboxProps<T extends object = ComboboxItem>
  extends Omit<ComboBoxProps<T>, 'children'> {
  label?: string;
  description?: string | null;
  children: ReactNode | ((item: T) => ReactNode);
  css?: CSSProperties;
  isFocused: boolean;
  ariaLabel?: string;
  inputSize?: number;
}

/**
 * @deprecated
 */
export const Combobox = forwardRef<
  HTMLInputElement,
  ComboboxProps<ComboboxItem>
>(function Combobox(props, ref) {
  const { isFocused, children, inputSize = 20, ...restProps } = props;
  return (
    <Field css={{ width: 'auto', overflow: 'hidden' }}>
      <Root menuTrigger="focus" {...restProps}>
        {/* Do not remove - This Button comes from React Aria, it is not visible in the UI, but rendering it is required for full Combobox functionality */}
        <Button />
        <Input ref={ref} size={inputSize} type="text" />
        <Popover data-test="combobox-popover" isOpen={isFocused} offset={0}>
          <ListBox
            data-test="combobox-list"
            renderEmptyState={() => (
              <Text kind="body-4" padding="$8">
                No results found. Please try again.
              </Text>
            )}
          >
            {children}
          </ListBox>
        </Popover>
      </Root>
    </Field>
  );
});
