import { useUncontrolled } from '@mantine/hooks';
import { forwardRef } from 'react';

import type { UseUncontrolledInput } from '../../types.js';
import { Field } from '../field/index.js';
import {
  type InputProps,
  type InputPropsWithFieldProps,
  Input,
  useInputProps,
} from '../input/index.js';

export type NumberInputProps = InputPropsWithFieldProps<
  InputProps,
  UseUncontrolledInput<number>
>;

/**
 * @deprecated Use `NumberField` in accomplice instead.
 */
export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(props: NumberInputProps, ref) {
    const { fieldProps, inputProps, ...restProps } = useInputProps(props);
    const { value, onChange, defaultValue, ...restInputProps } = inputProps;

    const [_value, handleChange] = useUncontrolled<number>({
      value,
      onChange,
      defaultValue,
    });

    return (
      <Field {...fieldProps}>
        <Input
          autoComplete="off"
          {...restProps}
          {...restInputProps}
          onChange={event => {
            // We know this is a number because `type="number"`
            handleChange(Number(event.currentTarget.value));
          }}
          ref={ref}
          type="number"
          value={_value}
        />
      </Field>
    );
  },
);
