import { Slot } from '@radix-ui/react-slot';
import { type ForwardedRef, type ReactNode, forwardRef } from 'react';
import type { LinkProps as RACLinkProps } from 'react-aria-components';
import { Link as RACLink } from 'react-aria-components';
import type { Merge, Simplify } from 'type-fest';

import type { RainbowSprinkles } from '../../rainbow-sprinkles.css.js';
import type { ElementProps } from '../../types.js';
import { getRecipeStyleProps } from '../../utilities/get-recipe-style-props.js';
import { type LinkVariants, linkStyles } from './link.css.js';

type CombinedLinkProps = LinkVariants & {
  asChild?: boolean;
  children?: ReactNode;
  css?: RainbowSprinkles;
} & Merge<ElementProps<'a'>, RACLinkProps>;

export type LinkProps = Simplify<CombinedLinkProps>;

function Link(
  { children, css, asChild, ...props }: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const Component = asChild ? Slot : RACLink;

  const { className, style, otherProps } = getRecipeStyleProps(
    linkStyles,
    props,
    css,
  );

  return (
    <Component
      data-test="link"
      {...otherProps}
      className={className}
      ref={ref}
      slot={otherProps.slot || undefined}
      style={style}
    >
      {children}
    </Component>
  );
}

const _Link = forwardRef(Link);
export { _Link as Link };
