// TODO: These breakpoints were not working with rem units.
export const breakpoints = {
  xsmall: '(min-width: 320px)',
  small: '(min-width: 421px)',
  // This `shmedium` breakpoint is specifically created for feature card carousels,
  // to allow more control over how many slides we display at a given breakpoint.
  // This should not be used as a general app-wide breakpoint.
  shmedium: '(min-width: 560px)',
  medium: '(min-width: 769px)',
  large: '(min-width: 1025px)',
  xlarge: '(min-width: 1367px)',
  // This `xxlarge` breakpoint is specifically created for ranker carousels,
  // to allow more control over how many slides we display at a given breakpoint.
  // This should not be used as a general app-wide breakpoint.
  xxlarge: '(min-width: 1600px)',
} as const;

export const maxBreakpoints = {
  largeMax: '(max-width: 1024px)',
} as const;

/**
 * note about device media-queries 02/16/22:
 * checking the pointer value appears to be more accurate than
 * the hover value in determining touch screens. identifying
 * touchscreens as `hover:none` currently excludes some android
 * devices that count a longpress as hover.
 * NB: `pointer` values aren't flawless but they misrepresent
 * devices we don't care about as much:
 * 1. some touchscreen stylus devices will be reported as 'pointer'
 * 2. wii-like devices will be reported as 'touch'
 *  */
export const devices = {
  pointer: '(pointer: fine)',
  touch: '(pointer: coarse)',
} as const;

export const preferences = {
  motion: '(prefers-reduced-motion: no-preference)',
  nomotion: '(prefers-reduced-motion)',
} as const;

export const media = {
  ...breakpoints,
  ...maxBreakpoints,
  ...devices,
  ...preferences,
} as const;
