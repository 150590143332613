import { useHref } from '@remix-run/react';

/**
 * Resolves the provided path to an absolute URL.
 *
 * @param path - A relative or absolute path
 * @returns An absolute URL created from the path. If a relative path is provided, the resulting URL will use the current document's origin (via Remix's `useHref()`). If an absolute path is provided, it will be returned as-is.
 */
export function useAbsoluteHref(path: string) {
  const relative = useHref(path);
  if (
    path.startsWith('https://') ||
    path.startsWith('http://') ||
    path.startsWith('mailto:')
  ) {
    return path;
  }
  return relative;
}
