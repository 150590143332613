import { type IconProps, Icon } from './icon.js';

export function CheckboxHalfChecked(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Checkbox Half Checked" {...props}>
      <svg viewBox="0 0 24 24">
        <path
          clipRule="evenodd"
          d="M5 3a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5Zm1 8a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2H6Z"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  );
}
