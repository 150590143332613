import { type ReactNode, forwardRef } from 'react';

import { Flex } from '../flex/index.js';

export type SlideProps = {
  children: ReactNode;
  loading?: boolean;
};

/**
 * @deprecated
 */
export const Slide = forwardRef<HTMLDivElement, SlideProps>(function Slide(
  { children, loading = false },
  ref,
) {
  return (
    <Flex
      css={{
        /**
         * Adding this until the following bugs are resolved by Firefox and Safari:
         *   1. Firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=1744289
         *   2. Safari: https://github.com/iamdustan/smoothscroll/issues/177#issuecomment-962530240
         *
         * Targets Chromium Only
         */
        '@supports (contain: paint) and (not (-moz-appearance: none))': {
          scrollSnapAlign: 'start',
          scrollSnapStop: 'normal',
        },

        /**
         * We can support scroll snapping here since we don't have programmtic scrolling on touch devices.
         */
        '@touch': {
          scrollSnapAlign: 'start',
          scrollSnapStop: 'normal',
        },

        /**
         * This is for Link elements around cards. They need to take up 100% of the Carousel height. - SR
         */
        '& > a': {
          height: '100%',
        },
      }}
      data-test="carousel-slide"
      direction="column"
      opacity={loading ? 0.25 : 1}
      overflow="hidden"
      ref={ref}
      transition="opacity ease 300ms"
    >
      {children}
    </Flex>
  );
});
