import { forwardRef } from 'react';

import { media } from '../../core/index.js';
import { Box } from '../box/index.js';
import { type ImageProps, Image } from '../image/index.js';
import { Logomark } from '../logos/logomark.js';
import { useFullScreen } from './player-root.js';

export const PlayerArtwork = forwardRef<
  HTMLImageElement,
  Pick<ImageProps, 'alt' | 'src' | 'lazy'>
>(function PlayerArtwork({ alt, src, lazy = true }, ref) {
  const [isFullScreen] = useFullScreen();

  return (
    <Box
      css={{
        [`@container ${media.xsmall}`]: {
          display: 'none',
        },

        [`@container ${media.small}`]: {
          display: isFullScreen ? 'none' : 'inherit',
        },

        '[data-player-type="fullscreen"] &': {
          display: 'none',
        },
      }}
      gridArea="artwork"
      height={{
        [`@container ${media.xsmall}`]: '6.2rem',
        [`@container ${media.large}`]: 'auto',
      }}
      paddingRight={{
        [`@container ${media.small}`]: '$8',
      }}
      placeSelf="baseline"
      width={{
        [`@container ${media.xsmall}`]: '7.2rem',
        [`@container ${media.large}`]: '9.6rem',
      }}
    >
      <Image
        alt={alt}
        aspectRatio={1}
        backgroundColor={{ dark: '$gray-550', light: '$gray-150' }}
        fallback={<Logomark fill="gray-300" size={24} />}
        filter="$drop-shadow-1"
        height="auto"
        lazy={lazy}
        ref={ref}
        src={src}
        width="100%"
      />
    </Box>
  );
});

PlayerArtwork.displayName = 'Player.Artwork';
