import type { SharedAppErrorBoundaryProps } from '@iheartradio/web.remix-shared/error/app-error-boundary.js';
import { AppErrorBoundary as SharedErrorBoundary } from '@iheartradio/web.remix-shared/error/app-error-boundary.js';
import { AppErrorProvider as SharedErrorProvider } from '@iheartradio/web.remix-shared/error/components/app-error-provider.js';

import { ROUTE_API_ERROR } from './templates/error-route';
import { UNKNOWN_ERROR } from './templates/error-unknown';

export const AppErrorBoundary = ({
  document: Document,
  errorOverride,
}: SharedAppErrorBoundaryProps) => {
  return (
    <SharedErrorBoundary
      document={Document}
      errorOverride={errorOverride}
      templates={{
        routes: ROUTE_API_ERROR,
        unknown: UNKNOWN_ERROR,
      }}
    >
      <SharedErrorProvider.AppErrorContainer>
        <SharedErrorProvider.AppErrorLayout>
          <SharedErrorProvider.AppErrorItem>
            <SharedErrorProvider.AppError />
          </SharedErrorProvider.AppErrorItem>
        </SharedErrorProvider.AppErrorLayout>
      </SharedErrorProvider.AppErrorContainer>
    </SharedErrorBoundary>
  );
};
