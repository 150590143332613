import { useUncontrolled } from '@mantine/hooks';
import type { SwitchProps as RadixSwitchProps } from '@radix-ui/react-switch';
import { forwardRef } from 'react';

import type { UseUncontrolledInput } from '../../types.js';
import { Box } from '../box/index.js';
import { Field } from '../field/index.js';
import { CancelIcon } from '../icons/cancel-icon.js';
import { CheckIcon } from '../icons/check-icon.js';
import {
  type BaseInputProps,
  type InputPropsWithFieldProps,
  useInputProps,
} from '../input/index.js';
import { Switch as Primitive, SwitchThumb } from './switch.primitive.js';

export type SwitchProps = InputPropsWithFieldProps<
  BaseInputProps,
  UseUncontrolledInput<boolean>
> &
  Omit<RadixSwitchProps, 'name'>;

/**
 * @deprecated
 */
export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  function Switch(props: SwitchProps, ref) {
    const { fieldProps, inputProps, defaultChecked, ...restProps } =
      useInputProps(props, {
        inlineLabel: true,
      });

    const { value, defaultValue, onChange, disabled, id, name, required } =
      inputProps;

    const [_value, handleChange] = useUncontrolled<boolean>({
      value,
      defaultValue,
      onChange,
    });

    return (
      <Field {...fieldProps}>
        <Primitive
          {...restProps}
          aria-disabled={disabled}
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          name={name}
          onCheckedChange={handleChange}
          ref={ref}
          required={required}
          value={_value ? 'on' : 'off'}
        >
          <SwitchThumb asChild>
            <Box
              css={{
                '@medium': {
                  top: '0.3rem',
                  left: '0.3rem',
                },
              }}
            >
              <CheckIcon
                fill={
                  disabled ?
                    { light: 'gray-200', dark: 'gray-500' }
                  : 'blue-500'
                }
                size={{ '@xsmall': 16, '@medium': 18 }}
              />
              <CancelIcon
                fill={
                  disabled ?
                    { light: 'gray-200', dark: 'gray-500' }
                  : 'gray-350'
                }
                size={{ '@xsmall': 16, '@medium': 18 }}
              />
            </Box>
          </SwitchThumb>
        </Primitive>
      </Field>
    );
  },
);
