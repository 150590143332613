import { Checkbox } from '@iheartradio/web.accomplice/checkbox';
import { Text } from '@iheartradio/web.accomplice/text';

type EmailOptInProps = {
  disabled?: boolean;
};

export const EmailOptIn = (props: EmailOptInProps) => {
  return (
    <Checkbox
      data-test="email-opt-in"
      defaultSelected
      id="email-opt-in"
      isDisabled={props.disabled}
      name="emailOptIn"
      value="1"
    >
      <Text
        as="p"
        css={{ color: props.disabled ? '$gray400' : 'inherit' }}
        kind={{
          medium: 'caption-2',
          mobile: 'caption-4',
        }}
      >
        Get emails for the latest news and updates from iHeart.
      </Text>
    </Checkbox>
  );
};
