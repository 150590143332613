import { type ButtonProps, Button } from '@iheartradio/web.accomplice/button';
import { Loading } from '@iheartradio/web.accomplice/icons';
import { Message } from '@iheartradio/web.accomplice/message';
import type { PasswordFieldProps } from '@iheartradio/web.accomplice/password-field';
import { PasswordField as PasswordInput } from '@iheartradio/web.accomplice/password-field';
import {
  RadioButton,
  RadioGroup,
} from '@iheartradio/web.accomplice/radio-group';
import type { TextFieldProps as TextInputProps } from '@iheartradio/web.accomplice/text-field';
import { TextField as TextInput } from '@iheartradio/web.accomplice/text-field';
import type { BaseConfig } from '@iheartradio/web.config';
import { isNotBlank } from '@iheartradio/web.utilities';
import { type ReactNode, useEffect, useState } from 'react';
import { useField, useIsSubmitting } from 'remix-validated-form';

type TextFieldProps = TextInputProps & {
  resetValue?: boolean;
  message?: string;
  hint?: string;
};

type GenderRadioGroupProps = {
  disabled?: boolean;
  error?: ReactNode;
  hint?: string;
  required?: boolean;
  defaultValue?: string;
  name: string;
  message?: ReactNode;
  genders: BaseConfig['account']['registration']['genders'];
  label?: string;
};

type SubmitButtonProps = {
  onClick?: ButtonProps['onPress'];
  label?: string;
  disabled?: ButtonProps['isDisabled'];
};

export function TextField({
  name,
  label,
  placeholder,
  autoComplete,
  type,
  defaultValue,
  message,
  disabled = false,
  isClearable = false,
  inputMode = 'text',
  required,
  hint,
  resetValue = false,
  ...rest
}: TextFieldProps) {
  if (!name) {
    throw new Error('A "name" prop is required for the TextField component.');
  }

  const { error, getInputProps } = useField(name);
  const [value, setValue] = useState(defaultValue ?? '');

  useEffect(() => {
    if (resetValue) {
      setValue('');
    }
  }, [resetValue]);

  return (
    <TextInput
      {...getInputProps({ id: name, label, placeholder, type })}
      autoComplete={autoComplete ?? name}
      defaultValue={defaultValue ?? ''}
      errorMessage={error ?? message}
      hint={hint}
      inputMode={inputMode}
      isClearable={isClearable}
      isDisabled={disabled}
      isInvalid={!!error}
      isRequired={required}
      {...(disabled ? { value } : {})}
      {...rest}
    />
  );
}

export function PasswordField({
  name = 'password',
  label,
  placeholder,
  isDisabled = false,
  description,
  onChange,
  ...rest
}: PasswordFieldProps) {
  const { error, getInputProps } = useField(name);
  const { onChange: onInputChange, ...inputProps } = getInputProps({
    // @ts-expect-error "id" prop does exist and is needed for form validation to work properly.
    id: name,
    label,
    placeholder,
  });

  const handleOnChange = (value: string) => {
    if (!onChange && onInputChange) {
      onInputChange(value);
    }

    if (onChange && onInputChange) {
      onInputChange(value);
      onChange(value);
    }
  };

  const descriptionComponent = (
    <>
      {error ?
        <Message hasIcon kind="error">
          {error.toString()}
        </Message>
      : null}
      {isNotBlank(description) && description}
    </>
  );

  return (
    <PasswordInput
      {...inputProps}
      autoComplete={name}
      description={descriptionComponent}
      errorMessage={error}
      isDisabled={isDisabled}
      isInvalid={!!error}
      onChange={handleOnChange}
      {...rest}
    />
  );
}

export function GenderRadioGroup({
  defaultValue,
  disabled,
  hint,
  name,
  genders,
  required,
  label,
}: GenderRadioGroupProps) {
  const { error } = useField(name);

  const mappedGenders = Object.entries(genders ?? []).map(
    (entry: [string, { label: string }]) => {
      return {
        value: entry[0],
        label: entry[1].label,
      };
    },
  );

  return (
    <RadioGroup
      data-test="gender-radio-group"
      defaultValue={defaultValue}
      hint={hint}
      isDisabled={disabled}
      isInvalid={!!error}
      isRequired={required}
      label={label}
      message={
        error ?
          <Message hasIcon kind="error">
            {error.toString()}
          </Message>
        : null
      }
      name={name}
      orientation="horizontal"
    >
      {mappedGenders.map(option => {
        return (
          <RadioButton
            data-test={`gender-${option.value}`}
            id={`gender-${option.value}`}
            isDisabled={disabled}
            key={option.label}
            value={option.value}
          >
            {option.label}
          </RadioButton>
        );
      })}
    </RadioGroup>
  );
}

export const SubmitButton = ({
  label,
  disabled,
  onClick,
}: SubmitButtonProps) => {
  const isSubmitting = useIsSubmitting();

  return (
    <Button
      color="red"
      data-test="submit-button"
      inline
      isDisabled={disabled || isSubmitting}
      kind="primary"
      onPress={onClick}
      size="large"
      type="submit"
    >
      {isSubmitting ?
        <Loading fill="brand-white" />
      : label}
    </Button>
  );
};
