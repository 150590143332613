import { type PropsWithChildren, useId } from 'react';
import { forwardRef } from 'react';

import type { VariantProps } from '../../types.js';
import { Button } from '../button/index.js';
import { Flex } from '../flex/index.js';
import {
  Colors,
  Label,
  RadioButtonCheckedIcon,
  RadioButtonIcon,
  StyledItem,
} from './radio-button.primitive.js';

export type RadioButtonProps = PropsWithChildren<
  VariantProps<typeof StyledItem> & {
    disabled?: boolean;
    value?: string;
    id?: string | undefined;
  }
>;

/**
 * @deprecated Use the `RadioButton` component from accomplice
 */

export const RadioButton = forwardRef<HTMLButtonElement, RadioButtonProps>(
  function RadioButton(
    { children, value = '', disabled = false, ...props },
    ref,
  ) {
    const id = useId();

    return (
      <Flex alignItems="center" justifyContent="center">
        <StyledItem
          aria-disabled={disabled}
          asChild
          data-test="radio-input"
          disabled={disabled}
          id={id}
          tabIndex={0}
          value={value}
          {...props}
          ref={ref}
        >
          <Button
            color={{ dark: 'white', light: 'gray' }}
            css={{
              '&[data-state="checked"]': {
                [`& ${RadioButtonCheckedIcon}`]: {
                  display: 'block',
                },
                [`& ${RadioButtonIcon}`]: {
                  display: 'none',
                },
              },
              '&[data-state="unchecked"]': {
                [`& ${RadioButtonCheckedIcon}`]: {
                  display: 'none',
                },
                [`& ${RadioButtonIcon}`]: {
                  display: 'block',
                },
              },

              disabled: {
                svg: {
                  light: {
                    fill: '$gray-300',
                  },
                  dark: {
                    fill: '$gray-400',
                  },
                },
              },

              focus: {
                outline: 'none',
              },
            }}
            disabled={disabled}
            kind="tertiary"
            size="icon"
          >
            <RadioButtonCheckedIcon
              css={{
                dark: { color: Colors.darkActive },
                light: { color: Colors.lightActive },
              }}
              size={{
                '@initial': '16',
                '@large': '24',
              }}
            />
            <RadioButtonIcon
              size={{
                '@initial': '16',
                '@large': '24',
              }}
            />
          </Button>
        </StyledItem>
        <Label aria-disabled={disabled} htmlFor={id}>
          {children}
        </Label>
      </Flex>
    );
  },
);
