import { type FlexProps, Flex } from '../flex/index.js';

/**
 * @deprecated
 */
export const DialogMetadata = ({ children, ...props }: FlexProps) => (
  <Flex
    flexDirection="column"
    gap="$16"
    padding={{ '@initial': '$24 $16', '@medium': '$24' }}
    {...props}
  >
    {children}
  </Flex>
);
