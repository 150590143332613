import { type ReactNode, useRef, useState } from 'react';
import type {
  TextFieldProps as RACTextFieldProps,
  ValidationResult,
} from 'react-aria-components';
import { Label as RACLabel } from 'react-aria-components';
import { isFunction } from 'remeda';

import { ErrorFilled } from '../../icons/error-filled.js';
import { VisibilityOff } from '../../icons/visibility-off.js';
import { VisibilityOn } from '../../icons/visibility-on.js';
import { Button } from '../button/button.js';
import { Input } from '../input/input.js';
import {
  useTextField,
  useTextFieldState,
} from '../text-field/use-text-field.js';
import {
  descriptionStyles,
  fieldErrorStyles,
  inputContainerStyles,
  inputWithButtonStyles,
  labelStyles,
  showButtonStyles,
  textFieldStyles,
} from './password-field.css.js';

export interface PasswordFieldProps extends RACTextFieldProps {
  description?: ReactNode;
  errorMessage?: string | ((validation: ValidationResult) => string);
  placeholder?: string;
  label?: ReactNode;
}

export function PasswordField(props: PasswordFieldProps) {
  const {
    description,
    placeholder = 'Enter password',
    label,
    errorMessage,
    isDisabled,
    isReadOnly,
    isRequired,
  } = props;
  const ref = useRef(null);
  const state = useTextFieldState(props);
  const {
    labelProps,
    descriptionProps,
    errorMessageProps,
    inputProps,
    ...validation
  } = useTextField({ validationBehavior: 'native', ...props }, state, ref);

  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const VisibilityIcon = show ? VisibilityOff : VisibilityOn;

  return (
    <div
      className={textFieldStyles}
      data-disabled={isDisabled || undefined}
      data-invalid={validation.isInvalid || undefined}
      data-readonly={isReadOnly || undefined}
      data-required={isRequired || undefined}
      data-test="PasswordField"
    >
      {label ?
        <RACLabel {...labelProps} className={labelStyles}>
          {label}
        </RACLabel>
      : null}
      <div className={inputContainerStyles}>
        <Input
          {...inputProps}
          className={inputWithButtonStyles}
          data-has-button
          placeholder={placeholder}
          ref={ref}
          type={show ? 'text' : 'password'}
        />
        <Button
          className={showButtonStyles}
          data-test="show-password-button"
          kind="tertiary"
          onPress={handleClick}
          size="icon"
        >
          <VisibilityIcon size={16} />
        </Button>
      </div>
      {validation.isInvalid ?
        // Error message
        <div {...errorMessageProps} className={fieldErrorStyles}>
          <ErrorFilled size={{ mobile: 16, medium: 18 }} />
          {errorMessage ?
            isFunction(errorMessage) ?
              errorMessage(validation)
            : errorMessage
          : validation.validationErrors.join(' ')}
        </div>
      : description ?
        // Description
        <div
          {...descriptionProps}
          className={descriptionStyles}
          slot="description"
        >
          {description}
        </div>
      : null}
    </div>
  );
}
