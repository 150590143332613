import * as RadixPopover from '@radix-ui/react-popover';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import { keyframes, Theme } from '../../stitches.config.js';
import { type BoxProps, Box } from '../box/index.js';
import { Button } from '../button/index.js';
import { Flex } from '../flex/index.js';
import { CancelIcon } from '../icons/cancel-icon.js';
import { ThemeProvider, useTheme } from '../theme/index.js';

export type PopoverProps = {
  children: ReactNode;
  align?: RadixPopover.PopoverContentProps['align'];
  open?: boolean;
  placement?: 'top' | 'bottom' | 'right' | 'left';
  showClose?: boolean;
  trigger: ReactNode;
  zIndex?: BoxProps['zIndex'];
};

// Fade in for popover content
const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

// Fade out for popover content
const fadeOut = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

/**
 * @deprecated Use Popover Component from `web.accomplice`
 */
export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  function Popover(
    {
      children,
      trigger,
      align = 'center',
      placement = 'top',
      showClose = false,
      open = false,
      zIndex = '$10',
    }: PopoverProps,
    ref,
  ) {
    const theme = useTheme();
    const reverseTheme = theme === Theme.Dark ? Theme.Light : Theme.Dark;

    return (
      <ThemeProvider controlled value={reverseTheme}>
        <RadixPopover.Root modal={false} open={open}>
          <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
          <RadixPopover.Portal>
            <RadixPopover.Content
              align={align}
              asChild
              avoidCollisions
              ref={ref}
              side={placement}
              sideOffset={5}
            >
              <Box
                backgroundColor={{ dark: '$gray-600', light: '$brand-white' }}
                borderRadius="$6"
                boxShadow="$elevation-4"
                css={{
                  '@motion': {
                    '&[data-state="open"]': {
                      animation: `${fadeIn} 300ms ease-in-out`,
                    },

                    '&[data-state="closed"]': {
                      animation: `${fadeOut} 300ms ease-in-out`,
                    },
                  },

                  '[id="radix-popover-arrow"]': {
                    dark: { fill: '$brand-white' },
                    light: { fill: '$gray-600' },
                  },
                }}
                data-theme={reverseTheme}
                maxWidth={{
                  '@initial': '80vw',
                  '@small': '60vw',
                  '@medium': '28rem',
                }}
                outline="none"
                padding="$16"
                zIndex={zIndex}
              >
                {showClose ?
                  <Flex justifyContent="flex-end" paddingBottom="$8">
                    <RadixPopover.Close asChild>
                      <Button
                        color={{ dark: 'white', light: 'gray' }}
                        kind="tertiary"
                        size="icon"
                      >
                        <CancelIcon />
                      </Button>
                    </RadixPopover.Close>
                  </Flex>
                : null}
                {children}
                <RadixPopover.Arrow
                  height={8}
                  id="radix-popover-arrow"
                  offset={24}
                  width={16}
                />
              </Box>
            </RadixPopover.Content>
          </RadixPopover.Portal>
        </RadixPopover.Root>
      </ThemeProvider>
    );
  },
);
