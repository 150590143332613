import { Box } from '@iheartradio/web.accomplice/box';
import { Link } from '@iheartradio/web.accomplice/link';
import { Notification } from '@iheartradio/web.accomplice/notification';
import { Stack } from '@iheartradio/web.accomplice/stack';
import { Text } from '@iheartradio/web.accomplice/text';
import { Link as RouterLink, useNavigation } from '@remix-run/react';
import { useEffect } from 'react';

import {
  PasswordField,
  SubmitButton,
  TextField,
} from '~app/components/input-fields/input-fields';
import { DoNotSellMyPersonalInformation } from '~app/components/privacy-terms-optout/do-not-sell';
import {
  type SocialLoginParams,
  SocialLogin,
} from '~app/components/social-login';
import { useSocialError } from '~app/hooks';

import { SocialLoginUserInfo } from '../social-login-user-info';
import { LoginPageErrors } from './components';

export type LoginPageErrors = Record<string, string> & {
  attempts?: { errorMessage: string; title: string };
  other?: string[];
};

export type LoginPageProps = {
  accessToken?: string;
  accessTokenType?: string;
  client_id?: string;
  errors: LoginPageErrors | null;
  fpHash: string;
  gender?: string;
  loginToken?: string | null;
  oauthUuid?: string;
  password?: string;
  redirectUrl?: string | null;
  redirect_uri?: string;
  showSocial: boolean;
  skipCheck?: boolean;
  socialAction?: SocialLoginParams['action'];
  toastMessage?: string;
  type?: 'credentials' | 'checkAccount' | 'createUser' | 'loginToken';
  userName?: string;
  zipCode?: string;
};

export default function LoginPage({
  accessToken,
  accessTokenType,
  client_id,
  errors,
  fpHash,
  loginToken,
  oauthUuid,
  password,
  redirectUrl,
  redirect_uri,
  showSocial = true,
  socialAction = '/social/login?index',
  type = 'credentials',
  userName,
}: LoginPageProps) {
  const transition = useNavigation();
  const isSubmitting = transition.state === 'submitting';

  const { SocialLoginErrorNotification, setProvider, setShowNotification } =
    useSocialError();

  const connectedToSocialProvider = !!(
    oauthUuid &&
    accessToken &&
    accessTokenType
  );
  const connectedTitle =
    connectedToSocialProvider ?
      accessTokenType?.toLowerCase() === 'fb' ? 'Connected with Facebook. '
      : accessTokenType?.toLowerCase() === 'google' ? 'Connected with Google. '
      : ''
    : '';

  // hide social error notification on submit
  useEffect(() => {
    if (isSubmitting) {
      setShowNotification(false);
    }
  }, [isSubmitting, setShowNotification]);

  return type === 'credentials' || type === 'loginToken' ?
      <>
        <Stack
          css={{
            paddingX: '$24',
          }}
          data-test="login-form"
          gap="$32"
        >
          <LoginPageErrors errors={errors}>
            <SocialLoginErrorNotification />
          </LoginPageErrors>
          {loginToken && !errors ?
            <Notification
              data-test="logging-you-in-notification"
              kind="info"
              title="Logging you in..."
            >
              Please wait while we log you in
            </Notification>
          : null}
          <TextField
            defaultValue={userName}
            isClearable
            label="Email"
            name="userName"
            placeholder="Enter email address"
            type="text"
          />
          <PasswordField
            defaultValue={password}
            label="Password"
            name="password"
            placeholder="Enter password"
          />
          <Box>
            <Text kind="caption-3">
              <Link asChild underline="hover">
                <RouterLink color="primary" to="/forgot-password">
                  Forgot your password?
                </RouterLink>
              </Link>
            </Text>
          </Box>
          {redirectUrl ?
            <input name="redirectUrl" type="hidden" value={redirectUrl} />
          : null}
          {fpHash ?
            <input name="fpHash" type="hidden" value={fpHash} />
          : null}
          {redirect_uri ?
            <input name="redirect_uri" type="hidden" value={redirect_uri} />
          : null}
          {client_id ?
            <input name="client_id" type="hidden" value={client_id} />
          : null}
          <input name="type" type="hidden" value={type} />
          <SubmitButton label="Log in" />
        </Stack>
        {showSocial ?
          <SocialLogin
            action={socialAction}
            client_id={client_id}
            fpHash={fpHash}
            redirect_uri={redirect_uri}
            setProvider={setProvider}
            setShowNotification={setShowNotification}
          />
        : null}
        <Box paddingLeft="$24" paddingTop="$24">
          <Text kind="caption-4">
            <DoNotSellMyPersonalInformation />
          </Text>
        </Box>
      </>
    : <SocialLoginUserInfo
        accessToken={accessToken}
        accessTokenType={accessTokenType}
        connectedTitle={connectedTitle}
        connectedToSocialProvider={connectedToSocialProvider}
        errors={errors}
        fpHash={fpHash}
        isSubmitting={isSubmitting}
        oauthUuid={oauthUuid}
        redirect_uri={redirect_uri}
        userName={userName}
      />;
}
