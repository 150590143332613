import { Dialog as _Dialog } from './dialog.js';
import { Trigger } from './dialog.primitive.js';
import { DialogBody } from './dialog-body.js';
import { DialogButtonContainer } from './dialog-button-container.js';
import { DialogMetadata } from './dialog-metadata.js';
import { DialogRoot } from './dialog-root.js';
import { DialogTitle } from './dialog-title.js';

/**
 * @deprecated
 */
export const Dialog = Object.assign(_Dialog, {
  Body: DialogBody,
  ButtonContainer: DialogButtonContainer,
  Metadata: DialogMetadata,
  Root: DialogRoot,
  Title: DialogTitle,
  Trigger,
});

export type { DialogBoxProps as DialogProps } from './dialog.js';
