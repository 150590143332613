import { type ReactNode, forwardRef, useId } from 'react';
import {
  type RadioProps as RACRadioProps,
  Label,
  Radio as RACRadio,
} from 'react-aria-components';

import { RadioChecked } from '../../icons/radio-checked.js';
import { RadioUnchecked } from '../../icons/radio-unchecked.js';
import {
  iconSelectedStyles,
  radioButtonRecipe,
  selectedStyles,
} from './radio-button.css.js';

export type RadioButtonProps = RACRadioProps & {
  id?: string;
  children?: ReactNode;
};

export const RadioButton = forwardRef<HTMLLabelElement, RadioButtonProps>(
  ({ children, value, isDisabled, ...props }, ref) => {
    const id = useId();

    return (
      <RACRadio
        className={radioButtonRecipe({ isDisabled })}
        data-test="radio-input"
        id={id}
        isDisabled={isDisabled}
        ref={ref}
        value={value}
        {...props}
      >
        {({ isSelected }) => (
          <>
            <div className={selectedStyles}>
              {isSelected ?
                <RadioChecked className={iconSelectedStyles} />
              : <RadioUnchecked className={iconSelectedStyles} />}
            </div>
            <Label aria-disabled={isDisabled} htmlFor={id}>
              {children}
            </Label>
          </>
        )}
      </RACRadio>
    );
  },
);

RadioButton.displayName = 'RadioButton';
