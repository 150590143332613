import { useUncontrolled } from '@mantine/hooks';
import type { CheckedState } from '@radix-ui/react-checkbox';
import type { ComponentProps } from '@stitches/react';
import { type PropsWithChildren, forwardRef, useId } from 'react';
import type { Merge } from 'type-fest';

import type { Component } from '../../types.js';
import { Flex } from '../flex/index.js';
import {
  Checkbox as Primitive,
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  Label,
} from './checkbox.primitive.js';

/**
 * @deprecated
 */
export type CheckboxProps = Merge<
  Omit<PropsWithChildren<ComponentProps<typeof Primitive>>, 'inlist'>,
  {
    name: string;
    onChange?: (state: CheckedState) => void;
    value?: string | undefined;
  }
>;

/**
 * @deprecated
 */
export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  function Checkbox(props: CheckboxProps, ref) {
    const {
      checked,
      defaultChecked,
      onChange,
      children,
      value,
      disabled,
      ...restProps
    } = props;

    const [_checked, handleChange] = useUncontrolled<CheckedState>({
      value: checked,
      onChange,
      defaultValue: defaultChecked,
      finalValue: false,
    });

    const id = useId();

    return (
      <Flex alignItems="center">
        <Primitive
          aria-disabled={disabled ?? restProps['aria-disabled']}
          disabled={disabled}
          id={id}
          {...restProps}
          checked={_checked}
          onCheckedChange={handleChange}
          ref={ref}
          value={value}
        >
          <CheckboxCheckedIcon
            size={{
              '@initial': '16',
              '@large': '24',
            }}
          />
          <CheckboxUncheckedIcon
            size={{
              '@initial': '16',
              '@large': '24',
            }}
          />
        </Primitive>
        <Label htmlFor={id}>{children}</Label>
      </Flex>
    );
  },
) as Component<'button', CheckboxProps>;
