import {
  type AriaToastProps,
  type AriaToastRegionProps,
  useToast,
  useToastRegion,
} from '@react-aria/toast';
import {
  type ToastState,
  type ToastStateProps,
  useToastQueue,
  useToastState,
} from '@react-stately/toast';
import {
  type FC,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useId } from 'react-aria';
import { createPortal } from 'react-dom';

import {
  type NotificationProps,
  Notification,
} from '../notification/notification.js';
import {
  globalToastRegionStyles,
  localToastRegionStyles,
  toastStyles,
} from './toast.css.js';
import { GlobalToastQueue } from './utils.js';

interface ToastRegionProps<T extends NotificationProps>
  extends AriaToastRegionProps {
  id?: string;
  state: ToastState<T>;
  className?: string;
  dataTest?: string | undefined;
}

interface ToastProps<T extends NotificationProps> extends AriaToastProps<T> {
  state: ToastState<T>;
}

export function GlobalToastRegion<T extends NotificationProps>(
  props: Omit<ToastRegionProps<T>, 'state'>,
) {
  const state = useToastQueue(GlobalToastQueue);

  return createPortal(
    <ToastRegion id="global-toast-region" {...props} state={state} />,
    document.body,
  );
}

export function ToastProvider<T extends NotificationProps>({
  children,
  ...props
}: Omit<ToastRegionProps<T>, 'state'> & {
  children: (state: ToastState<T>) => ReactNode;
}) {
  const state = useToastState<T>({
    maxVisibleToasts: 5,
    hasExitAnimation: true,
  });

  return (
    <>
      {children(state)}
      <ToastRegion
        className={localToastRegionStyles}
        {...props}
        state={state}
      />
    </>
  );
}

type UseToastProvider = {
  addToast: ReturnType<typeof useToastState>['add'];
  closeToast: ReturnType<typeof useToastState>['close'];
  Region: FC;
};

/**
 * To use scoped Toast methods outside of the rendered Provider.
 *
 * @param param ToastStateProps
 * @returns UseToastProvider
 */
export function useToastProvider(props?: ToastStateProps): UseToastProvider {
  const { maxVisibleToasts = 5, hasExitAnimation = true } = props ?? {};

  const state = useToastState<NotificationProps>({
    maxVisibleToasts,
    hasExitAnimation,
  });

  return useMemo(
    () => ({
      addToast: (
        content: Parameters<typeof state.add>[0],
        options: Parameters<typeof state.add>[1],
      ) => {
        return state.add(content, { timeout: 5000, ...options });
      },
      closeToast: state.close,
      Region: function UseToastRegion() {
        return <ToastRegion className={localToastRegionStyles} state={state} />;
      },
    }),
    [state],
  );
}

function ToastRegion<T extends NotificationProps>({
  state,
  className = globalToastRegionStyles,
  id,
  dataTest,
  ...props
}: ToastRegionProps<T>) {
  const generatedId = useId();
  const ref = useRef<HTMLDivElement | null>(null);
  // Storing this value in a ref so it doesn't cause re-renders
  const currentHeight = useRef<number>(0);
  const currentlyDisplayedToasts = useRef<number>(0);

  const { regionProps } = useToastRegion(
    {
      ...props,
      'aria-label':
        className === globalToastRegionStyles ?
          'Global Notifications'
        : 'Local Notifications',
    },
    state,
    ref,
  );

  // The height of the ToastRegion needs to be explicitly set, so that the transition FOR height
  // will animate correctly.
  // This callback gets passed to the Toast component, and when the relevant animations begin or end
  // this callback is executed and the height value is updated
  const handleResize = useCallback(
    (
      itemHeight: number | undefined,
      direction: 'grow' | 'shrink' | 'queued',
    ) => {
      if (!itemHeight) return;

      const heightChange =
        itemHeight + (currentlyDisplayedToasts.current > 0 ? 8 : 0);

      // Determine the new height of this container based on the direction argument
      if (direction === 'grow') {
        currentlyDisplayedToasts.current = currentlyDisplayedToasts.current + 1;
        currentHeight.current += heightChange;
      } else if (direction === 'shrink') {
        currentlyDisplayedToasts.current = currentlyDisplayedToasts.current - 1;
        currentHeight.current -= heightChange;
      }

      if (currentlyDisplayedToasts.current < 0) {
        currentlyDisplayedToasts.current = 0;
      }

      if (currentlyDisplayedToasts.current === 0) {
        currentHeight.current = 0;
      }

      // Set the height on the style prop using the ToastRegion ref
      if (ref.current) {
        ref.current.style.height = `${currentHeight.current}px`;
      }
    },
    [],
  );

  return (
    <div
      {...regionProps}
      className={className}
      id={id ?? generatedId}
      onAnimationEnd={() => ref.current?.removeAttribute('data-animation')}
      ref={ref}
    >
      {state.visibleToasts.map(toast => (
        <Toast
          dataTest={dataTest}
          handleResize={handleResize}
          isGlobal={className === globalToastRegionStyles}
          key={toast.key}
          parentRef={ref}
          state={state}
          toast={toast}
        />
      ))}
    </div>
  );
}

export function Toast<T extends NotificationProps>({
  state,
  handleResize,
  isGlobal = true,
  parentRef,
  dataTest,
  ...props
}: ToastProps<T> & {
  handleResize: (
    itemHeight: number | undefined,
    direction: 'grow' | 'shrink' | 'queued',
  ) => void;
  isGlobal?: boolean;
  parentRef?: MutableRefObject<HTMLDivElement | null>;
  dataTest?: string | undefined;
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  const { toastProps, contentProps, titleProps, closeButtonProps } = useToast(
    props,
    state,
    ref,
  );

  // If any of the `action` passed have the `shouldDismiss: true` property, we don't want
  // to show the native close button.
  const hasDismissAction = (props.toast.content.actions ?? []).some(
    action => action.shouldDismiss,
  );
  const showClose = props.toast.content.showClose ?? !hasDismissAction;

  return (
    // A wrapper for `Notification` which handles the `entering`, `queued` and `exiting` animations
    <div
      className={toastStyles}
      data-animation={props.toast.animation}
      data-test="toast-notification"
      // When the exiting animation completes, then the Toast is removed from the queue
      // and the container resize callback is executed
      onAnimationEnd={() => {
        if (props.toast.animation === 'exiting') {
          state.remove(props.toast.key);
          // handleResize(ref.current?.scrollHeight, 'shrink');
        }
        ref.current?.removeAttribute('data-animation');
      }}
      onAnimationStart={() => {
        requestAnimationFrame(() => {
          if (
            props.toast.animation &&
            !parentRef?.current?.hasAttribute('data-animation')
          ) {
            parentRef?.current?.setAttribute(
              'data-animation',
              props.toast.animation,
            );
          }
        });
        // If the Toast is exiting, we need to change it's position to `fixed` while it is
        // exiting so that it doesn't "push" the other toast items out of the way
        if (props.toast.animation === 'exiting' && isGlobal) {
          // Doing this in `requestAnimationFrame` so that all the style changes happen at once
          // since we have to set `position`, `left`, `top` and `width` separately because
          // `style` is a read-only property and cannot be set in it's entirety with something
          // like:
          // ref.current.style = {
          //   ...ref.current.style,
          //   position: 'fixed',
          //   left: `${x}px`,
          //   top: `${y}px`,
          //   width: `${width}px`
          // }
          requestAnimationFrame(() => {
            if (ref.current) {
              const { x, y, width } = ref.current.getBoundingClientRect();
              ref.current.style.position = 'fixed';
              ref.current.style.left = `${x}px`;
              ref.current.style.top = `${y}px`;
              ref.current.style.width = `${width}px`;
              handleResize(ref.current?.scrollHeight, 'shrink');
            }
          });
          // Else if the toast is 'entering' or 'queued', then we need to grow the container
        } else if (props.toast.animation === 'entering') {
          requestAnimationFrame(() => {
            if (ref.current) {
              handleResize(ref.current.scrollHeight, 'grow');
            }
          });
        } else if (props.toast.animation === 'queued') {
          requestAnimationFrame(() => {
            if (ref.current) {
              handleResize(ref.current?.scrollHeight, 'queued');
            }
          });
        }
      }}
      ref={ref}
      {...toastProps}
    >
      <Notification
        actions={props.toast.content.actions}
        closeButtonProps={closeButtonProps}
        contentProps={contentProps}
        data-test={dataTest}
        kind={props.toast.content.kind}
        showClose={showClose}
        title={props.toast.content.title}
        titleProps={titleProps}
      >
        {props.toast.content.children}
      </Notification>
    </div>
  );
}
