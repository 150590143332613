import type { ComponentProps } from '@stitches/react';
import { type ForwardedRef, forwardRef, useRef } from 'react';
import { type AriaButtonOptions, useButton } from 'react-aria';
import { isNonNullish, isPlainObject } from 'remeda';

import type { Component } from '../../types.js';
import { useTheme } from '../theme/index.js';
import { Button as Primitive } from './button.primitive.js';

/**
 * @deprecated
 */
export interface ButtonProps extends ComponentProps<typeof Primitive> {
  onPress?: AriaButtonOptions<'button'>['onPress'];
  continuePropagation?: boolean;
}

/**
 * @deprecated
 */
function _Button(
  {
    children,
    color,
    disabled = false,
    inline = true,
    type = 'button',
    continuePropagation = false,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  if (isNonNullish(props.onClick) && isNonNullish(props.onPress)) {
    throw new Error(
      'The web.companion Button accepts either native events (onClick, onKeyDown, onPointerDown, etc.) or RAC events (onPress, etc.), not both.',
    );
  }

  const theme = useTheme();
  const fallbackRef = useRef(null);
  const finalRef = ref ?? fallbackRef;

  const { buttonProps } = useButton(
    { ...props, preventFocusOnPress: true },
    finalRef,
  );

  return (
    <Primitive
      {...props}
      {...buttonProps}
      {...{
        onClick: event => {
          // Prevent the event from bubbling
          if (!continuePropagation) {
            event.stopPropagation();
          }

          if (props?.onClick) {
            props?.onClick?.(event);
          } else {
            buttonProps.onClick(event);
          }
        },
        onKeyDown: event => {
          // Prevent the event from bubbling
          if (!continuePropagation) {
            event.stopPropagation();
          }

          if (props?.onKeyDown) {
            props?.onKeyDown?.(event);
          } else {
            buttonProps.onKeyDown(event);
          }
        },

        onPointerDown: event => {
          // Prevent the event from bubbling
          if (!continuePropagation) {
            event.stopPropagation();
          }

          if (props?.onPointerDown) {
            props?.onPointerDown?.(event);
          } else {
            buttonProps.onPointerDown(event);
          }
        },
      }}
      aria-disabled={disabled}
      color={isPlainObject(color) ? color[theme] : color}
      disabled={disabled}
      inline={inline}
      ref={finalRef}
      type={type}
    >
      {children}
    </Primitive>
  );
}

const Button = forwardRef(_Button) as Component<'button', ButtonProps>;

export { Button };
