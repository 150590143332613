import { type IconProps, Icon } from './icon.js';

export function CheckboxChecked(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Checkbox Checked" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M19 3H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Zm-8.29 13.29a1 1 0 0 1-1.41 0L5.71 12.7a1 1 0 1 1 1.41-1.41L10 14.17l6.88-6.88a1 1 0 1 1 1.41 1.41l-7.58 7.59Z" />
      </svg>
    </Icon>
  );
}
