import { Theme } from '@iheartradio/web.companion';
import { useLocation, useRouteError } from '@remix-run/react';
import type { ComponentType, PropsWithChildren } from 'react';

import type { DocumentSharedProps } from '../../lib/document.js';
import { AppErrorProvider } from './components/index.js';
import { useErrorConfig } from './hooks/use-error-config.js';
import type { ErrorTemplates } from './types/error-config.js';
import type { ErrorResponse } from './types/error-response.js';

export type SharedAppErrorBoundaryProps = {
  document?: ComponentType<DocumentSharedProps>;
  errorOverride?: unknown;
};

type AppErrorBoundaryProps = {
  templates: ErrorTemplates;
} & SharedAppErrorBoundaryProps;

export const AppErrorBoundary = ({
  children,
  document: Document,
  errorOverride,
  templates,
}: PropsWithChildren<AppErrorBoundaryProps>) => {
  const { pathname, search } = useLocation();
  const caughtError = useRouteError();

  let error = errorOverride || caughtError;

  const queryErrorOverride = new URLSearchParams(search).get('error');
  if (queryErrorOverride && /^\d{3}$/.test(queryErrorOverride)) {
    error = {
      // network error
      status: +queryErrorOverride,
      statusText: '',
      internal: false,
      data: {},
    } as ErrorResponse;
  } else if (queryErrorOverride && queryErrorOverride.length > 4) {
    error = new Error(queryErrorOverride); // app error
  } else if (queryErrorOverride) {
    error = null; // unknown error
  }

  const errorConfig = useErrorConfig({
    error,
    pathname,
    root: !!Document,
    templates,
  });

  return Document ?
      <Document
        rootError={true}
        theme={Theme.Light}
        {...{
          children: (
            <>
              <AppErrorProvider errorConfig={errorConfig}>
                {children}
              </AppErrorProvider>
            </>
          ),
        }}
      />
    : <AppErrorProvider errorConfig={errorConfig}>{children}</AppErrorProvider>;
};
