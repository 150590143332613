import { type IconProps, Icon } from './icon.js';

export function RadioUnchecked(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Radio Unchecked" {...props}>
      <svg viewBox="0 0 24 24">
        <path
          clipRule="evenodd"
          d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10 10 10 0 0 0 10-10A10 10 0 0 0 12 2Zm0 18a8 8 0 0 1-8-8 8 8 0 0 1 8-8 8 8 0 0 1 8 8 8 8 0 0 1-8 8Z"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  );
}
