import { Notification } from '@iheartradio/web.accomplice/notification';
import { isNotBlank } from '@iheartradio/web.utilities';

import { LOGIN as VALIDATION_ERRORS } from '~app/constants/validation-errors';

import type { LoginPageErrors } from './login-page';

type LoginPageErrorsProps = {
  children: React.ReactNode;
  errors: LoginPageErrors | null;
};
export function LoginPageErrors(props: LoginPageErrorsProps) {
  const { errors, children } = props;

  return (
    <>
      {children}
      {isNotBlank(errors?.other) ?
        <Notification
          data-test="error-notification"
          kind="error"
          showClose
          title={errors.other[0]}
        />
      : null}
      {(
        errors?.userName === VALIDATION_ERRORS.EMAIL_ERROR ||
        errors?.password === VALIDATION_ERRORS.PASSWORD_NOT_VALID
      ) ?
        <Notification
          actions={[
            {
              kind: 'primary',
              size: 'small',
              color: 'white',
              href: '/forgot-password',
              content: 'Forgot Password',
            },
          ]}
          data-test="wrong-credentials-notification"
          kind="error"
          showClose
          title="Wrong email or password"
        >
          Please try again with a different email address or password or try
          logging in another way
        </Notification>
      : null}
      {errors?.attempts && errors?.attempts.errorMessage ?
        <Notification
          actions={[
            {
              kind: 'primary',
              size: 'small',
              color: 'white',
              href: '/forgot-password',
              dataTest: 'forgot-password-error-notification',
              content: 'Forgot Password',
            },
          ]}
          kind="error"
          showClose
          title={errors?.attempts?.title}
        >
          {errors?.attempts?.errorMessage}
        </Notification>
      : null}
      {errors?.userName === VALIDATION_ERRORS.EMAIL_OAUTH ?
        <Notification
          data-test="oauth-error-notification"
          kind="neutral"
          showClose
          title={errors?.userName ?? ''}
        >
          Please try logging in with {errors?.oauthProvider}
        </Notification>
      : null}
      {errors?.token ?
        <Notification
          actions={[
            {
              kind: 'primary',
              size: 'small',
              color: 'white',
              href: '/forgot-password',
              content: 'Generate Login Link',
              dataTest: 'generate-login-link-button',
            },
          ]}
          kind="warning"
          title={errors?.token}
        >
          Please check the link, or generate a new one
        </Notification>
      : null}
    </>
  );
}
